export default function SignInCarouselSlides({
  index,
  charCode,
  backgroundColor,
  iconUrl,
  customHeightMobile,
  customHeightDesktop,
}) {
  const textColor = (code) => {
    switch (code) {
      case "E":
        return "The Persuaders";
      case "C":
        return "The Organizer";
      default:
        return "white";
    }
  };

  return (
    <div
      className="w-screen h-full relative"
      style={{
        backgroundColor: backgroundColor,
        minHeight:
          window.innerWidth < 1024
            ? customHeightMobile
              ? customHeightMobile
              : 661
            : customHeightDesktop
            ? customHeightDesktop
            : 514,
        height: window.innerHeight - (window.innerWidth < 1024 ? 210 : 145),
      }}
    >
      {window.location.pathname === "/signup" && window.innerWidth >= 1024 && (
        <div
          className="flex flex-col justify-start items-start absolute z-10"
          style={{ gap: "21.33px", top: "32px", left: "71.11px" }}
        >
          <p
            className="text-left font-bold text-40px"
            style={{
              lineHeight: "48px",
              letterSpacing: "-1.07px",
              color: textColor(charCode),
            }}
          >
            Selamat datang
          </p>
          <p
            className="text-left font-medium text-base"
            style={{ lineHeight: "24px", color: textColor(charCode) }}
          >
            Raih masa depanmu bersama Exploration!
          </p>
        </div>
      )}
      <img src={iconUrl} className="absolute bottom-0 left-0" />
    </div>
  );
}
