import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";

export default function SpeakerCard({
  item,
  index,
  mobile,
  currentSpeaker,
  totalSpeakers,
  prevClick,
  scrollX,
  nextClick,
  scrolEnd,
}) {
  return (
    <div
      className={`relative flex flex-col ${
        mobile ? "w-full" : "w-max"
      } gap-4 p-4 hd:p-6 rounded-xl bg-white`}
      style={{
        minWidth: `${!mobile ? "632.89px" : "300px"}`,
        maxWidth: `${!mobile ? "632.89px" : "300px"}`,
        height: `${
          item.description === "" ? "263px" : mobile ? "336px" : "388px"
        }`,
      }}
    >
      <div className="flex flex-row gap-2 hd:gap-6 items-center justify-start">
        <img
          alt="profile icon"
          src={item.image_url !== "" ? item.image_url : "/icon_profile.svg"}
          className="w-20 h-20 hd:w-30 hd:h-30 rounded-xl object-cover"
        />
        <div className="flex flex-col justify-center items-start gap-2 hd:gap-3">
          <p
            className="text-left font-semibold text-base tablet:text-xl text-black1 line-clamp-2 overflow-ellipsis"
            style={{
              lineHeight: mobile ? "17.28px" : "21.6px",
              letterSpacing: mobile ? "-0.87px" : "-0.71px",
            }}
          >
            {item.name}
          </p>
          <p
            className="text-left font-normal text-sm tablet:text-base"
            style={{
              color: "rgba(139, 139, 139, 1)",
              lineHeight: mobile ? "16.8px" : "17.28px",
              letterSpacing: !mobile && "-0.71px",
            }}
          >
            {item.work}
          </p>
        </div>
      </div>
      <p
        className="text-left font-normal text-base text-black"
        style={{
          lineHeight: "24.8px",
        }}
      >
        {item.description}
      </p>
      {!mobile && (
        <div className="flex flex-row gap-2 items-center justify-end absolute bottom-6 right-6">
          <p
            className="text-center font-normal text-base text-black1"
            style={{ opacity: "0.6" }}
          >
            {currentSpeaker + 1}/{totalSpeakers}
          </p>
          <button
            onClick={() => {
              prevClick();
            }}
            disabled={scrollX === 0 || currentSpeaker === 0}
            className={`bg-tosca2 rounded-lg p-3 ml-10 ${
              scrollX === 0 || currentSpeaker === 0
                ? "opacity-30"
                : "opacity-100"
            }`}
          >
            <ArrowLeftIcon className="w-4 h-4 mac:w-5 mac:h-5" color="white" />
          </button>
          <button
            onClick={() => {
              nextClick();
            }}
            disabled={scrolEnd || currentSpeaker === totalSpeakers - 1}
            className={`bg-tosca2 rounded-lg p-3 ${
              scrolEnd || currentSpeaker === totalSpeakers - 1
                ? "opacity-30"
                : "opacity-100"
            }`}
          >
            <ArrowRightIcon className="w-4 h-4 mac:w-5 mac:h-5" color="white" />
          </button>
        </div>
      )}
    </div>
  );
}
