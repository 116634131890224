import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import ButtonV2 from './ButtonV2';

export default function RegisterEmailCheck({ isOpen, onClose, onReset, userEmail }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-100" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-black/25"
            style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
          />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div
            className="flex min-h-full items-center justify-center text-center"
            style={{ paddingLeft: "25px", paddingRight: "35.2px" }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className="w-full max-w-md transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all"
                style={{ borderRadius: "12.33px" }}
              >
                <div className="w-full flex flex-col justify-start items-center gap-5">
                  <p
                    className="text-center font-bold text-2xl text-black"
                    style={{ lineHeight: "30.24px" }}
                  >
                    Email Sudah Terdaftar
                  </p>
                  <p
                    className="text-center font-medium text-sm text-black"
                    style={{ lineHeight: "17.78px" }}
                  >
                    <span>
                      Jika kamu ingin menggunakan email tersebut, seluruh
                      aktivitas yang ada pada akunmu akan hilang.
                    </span>
                    <br />
                    <br />
                    <span>Apakah kamu ingin reset akunmu?</span>
                  </p>
                  <div className="w-full flex flex-col justify-start items-stretch gap-3">
                    <ButtonV2
                      title="Reset Akun"
                      onClick={() => onReset(userEmail)}
                    />
                    <ButtonV2
                      title="Cancel"
                      secondary={true}
                      onClick={onClose}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
