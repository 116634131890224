import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { api, apiAnom, notifError } from "../utils/api";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";
import LayoutV2 from "../components/Layout_V2";
import ButtonV2 from "../components/ButtonV2";
import charCardInfo from "./CharCardInfo.json";
import SignInCarouselSlides from "../components/SignInCarouselSlides";
import Slider from "react-slick";


function ValidateOTP() {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [countdown, setCountdown] = useState(30);
  const setAuth = useSetRecoilState(authState);
  const auth = useRecoilValue(authState);
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const charCardsRef = useRef();
  const location = useLocation();
  const [otpError, setOtpError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const email = location.state?.email;

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    try {
      const otpInt = parseInt(otp.join(''));
      await apiAnom.post("/auth/email-otp/verif", {
        email: email,
        otp: otpInt
    });
      // Check if it's B2B flow
      if (location.pathname.includes('/user')) {
        navigate('/b2b-reset-account', {
          state: {
            email: email,
            username: location.state?.username,
            first_name: location.state?.first_name,
            last_name: location.state?.last_name
          }
        });
      } else {
        // Regular flow
        navigate('/reset-account', {
          state: {
            email: email,
            username: location.state?.username,
            first_name: location.state?.first_name,
            last_name: location.state?.last_name
          }
        });
      }
    } catch (err) {
      setOtpError(true);
      setOtp(['', '', '', '', '', '']);
      setErrorMessage("Kode OTP yang dimasukkan tidak sesuai");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  var screenWidth = windowSize.innerWidth;
  // Update handleResendOTP to reset countdown
    const handleResendOTP = async () => {
        try {
          await apiAnom.post("/auth/email-otp/send", {
            email: email,
            first_name: location.state?.first_name,
            last_name: location.state?.last_name,
            username: location.state?.username
          });
        setCountdown(30); // Reset countdown to 30 seconds
        } catch (err) {
        notifError(err);
        }
    };

    const charCardsSettings = {
        arrows: false,
        className: "center",
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 3000,
    };
    
      const charCards = charCardInfo.map((item, index) => (
        <SignInCarouselSlides
          key={index}
          backgroundColor={item.backgroundColor}
          iconUrl={
            window.innerWidth < 1024 ? item.iconUrlMobile : item.iconUrlDesktop
          }
          customHeightMobile="916px"
          customHeightDesktop="639.78px"
        />
      ));

  return (
    <LayoutV2 padding="0">
        <div className="w-full sm:flex sm:justify-end relative"
            style={{
            padding:
                window.innerWidth < 1024 ? "27px 35.2px 0px 25px" : "32px 71px",
            minHeight: window.innerWidth < 1024 ? "916px" : "639.78px",
            height: window.innerHeight - (window.innerWidth < 1024 ? 210 : 145),
            }}
        >
        <div className="w-screen h-full absolute top-0 left-0">
          <Slider ref={charCardsRef} {...charCardsSettings}>
            {charCards.map((item) => item)}
          </Slider>
        </div>
        <div className="w-full h-full flex flex-col justify-start items-stretch relative z-10"
          style={{
            gap: "15px",
            width: window.innerWidth >= 1024 && "485px",
          }}>
          <div className="w-full flex flex-col bg-white p-6 gap-5 sm:gap-6"
            style={{ borderRadius: "12.33px" }}>
            <p className="text-left font-bold text-2xl text-black mt-4">Masukkan OTP</p>
            <p className="text-left text-black"> Kami telah mengirimkan kode OTP ke email <span className="text-primaryColor">{email}</span>, silahkan input kode OTP di bawah ini.</p>
            
            <form onSubmit={onSubmit} className="w-full flex flex-col gap-5 sm:gap-6 mb-4">
              <div className={`flex justify-between gap-2 ${screenWidth < 1024 ? 'w-full': 'w-3'} md:my-1`}>
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    placeholder="_"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => {
                      setOtpError(false);
                      const newOtp = [...otp];
                      newOtp[index] = e.target.value;
                      setOtp(newOtp);
                      
                      if (e.target.value && index < 5) {
                        document.getElementById(`otp-${index + 1}`).focus();
                      }
                    }}
                    id={`otp-${index}`}
                    className={`${screenWidth < 1024 ? 'w-full h-12' : 'w-12 h-15'} text-center border rounded-xl text-xl ${
                      otpError 
                        ? 'border-red-500 placeholder-red-500' 
                        : 'border-gray-300 placeholder-gray-400'
                    }`}
                  />
                ))}
              </div>
                {errorMessage && (
                  <p className="text-red-500 text-sm -mt-4">{errorMessage}</p>
                )}

              <div className="text-center flex items-center gap-1">
                <p>Belum menerima kode OTP?</p>
                <button
                    type="button"
                    onClick={handleResendOTP}
                    disabled={countdown > 0}
                    className={`text-primaryColor hover:text-purple-700 ${
                    countdown > 0 ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                >
                    Kirim ulang OTP
                </button>
                {countdown > 0 && <p>dalam {countdown}s</p>}
            </div>

              <ButtonV2 
                type="submit"
                title="Lanjut"
                disabled={loading || otp.some(digit => !digit)}
              />
            </form>
          </div>
        </div>
      </div>
    </LayoutV2>
  );
}

export default ValidateOTP;
