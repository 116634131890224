import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function HomeCardClassV2({
  item,
  mobile,
  popular,
  isExclusive,
}) {
  moment.tz.add(
    "Asia/Jakarta|LMT BMT +0720 +0730 +09 +08 WIB|-77.c -77.c -7k -7u -90 -80 -70|012343536|-49jH7.c 2hiLL.c luM0 mPzO 8vWu 6kpu 4PXu xhcu|31e6"
  );

  const [days, setDays] = useState("00");
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");
  const navigate = useNavigate();
  const [daysLeft, setDaysLeft] = useState(7);

  useEffect(() => {
    if (isExclusive) {
      setDaysLeft(999);
    }
  }, []);

  let interval = useRef();

  const startDate = new Date(item.started_at).getTime();

  const startTimer = () => {
    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = startDate - now;

      const remainingDays = Math.floor(distance / (1000 * 60 * 60 * 24));
      const remainingHours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const remainingMinutes = Math.floor(
        (distance % (1000 * 60 * 60)) / (1000 * 60)
      );
      const remainingSeconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setDays(remainingDays);
        setHours(remainingHours);
        setMinutes(remainingMinutes);
        setSeconds(remainingSeconds);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  let baseOccupancy = item.subscriber / item.capacity;
  let occupancy = baseOccupancy;

  if (baseOccupancy > 0 && baseOccupancy < 0.6) {
    occupancy = baseOccupancy + 0.05;
  }
  const occupancyStatus = (occupancy) => {
    if (occupancy === 0) {
      return "Tersedia";
    }
    if (occupancy > 0 && occupancy <= 0.3) {
      return "Tersedia";
    }
    if (occupancy > 0.3 && occupancy <= 0.6) {
      return "Lagi diminati";
    }
    if (occupancy > 0.6 && occupancy < 1) {
      return "Segera penuh 🔥";
    }
    if (occupancy === 1) {
      return "Penuh 🔥";
    }
  };

  return (
    <div
      style={{
        outline: "none",
        paddingTop: `${popular && "28px"}`,
        paddingBottom: `${popular && "44px"}`,
        // maxWidth: screenWidth < 1024 ? "325px" : "365.33px",
        // maxHeight: screenWidth < 1024 ? "432.75px" : "372.44px",
      }}
    >
      <button
        onClick={() => {
          navigate(
            window.location.pathname === "/explore-summer-camp"
              ? `/explore-summer-camp/${item.slug}`
              : `/explore-kelas/${item.slug}`
          );
        }}
        className="flex flex-col items-stretch justify-start rounded-xl bg-white relative shadow-md hover:shadow-2xl w-64"
        style={{
          outline: "none",
          width: mobile ? "325px" : "270px",
          height: mobile
            ? item.tmb_result_matched
              ? "437.33px"
              : "437.33px"
            : "280px",
          // minHeight: `${
          //   mobile ? (item.tmb_result_matched ? "500px" : "465px") : "535px"
          // }`,
          // maxHeight: `${
          //   mobile ? (item.tmb_result_matched ? "500px" : "465px") : "535px"
          // }`,
          // filter: "drop-shadow(24px 24px 200px rgba(23, 23, 23, 0.08))",
        }}
      >
        {days < daysLeft && days > 0 && item.is_past_classes === 0 && (
          <div
            className="rounded-tl-xl rounded-br-40px h-8 absolute top-0 left-0 bg-redTM flex items-center justify-start"
            style={{ width: mobile ? "160px" : "190px" }}
          >
            <p className="text-left font-semibold text-xs tablet:text-sm text-white ml-3 tablet:ml-4">{`${
              days < 10 ? "0" : ""
            }${days}d : ${hours < 10 ? "0" : ""}${hours}h : ${
              minutes < 10 ? "0" : ""
            }${minutes}m : ${seconds < 10 ? "0" : ""}${seconds}s`}</p>
          </div>
        )}
        <img
          src={item.img_url}
          alt="img"
          className="w-full object-cover rounded-t-xl"
          style={{
            minHeight: `${mobile ? "119.17px" : "76.16px"}`,
            maxHeight: `${mobile ? "119.17px" : "76.16px"}`,
          }}
        />
        {isExclusive && (
          <div className="w-full flex justify-end relative">
            <img
              src="/vec_exclass_banner.svg"
              className="object-contain object-right w-max"
              style={{
                minHeight: mobile ? "26px" : "36px",
                maxHeight: mobile ? "26px" : "36px",
              }}
            />
            <div
              className="absolute top-1"
              style={{ right: mobile ? "10px" : "14px", top: !mobile && "6px" }}
            >
              <p className="text-right font-semibold text-xss tablet:text-sm text-white relative">
                Summer Camp Exclusive
                <img
                  src="/star-4point.svg"
                  className="absolute -right-2 top-0"
                  style={{ maxWidth: "11px", maxHeight: "10px" }}
                />
              </p>
            </div>
            <div
              className="flex flex-row justify-end items-center absolute"
              style={{
                right: mobile ? "152px" : "208px",
                top: mobile ? "7px" : "10px",
              }}
            >
              <img
                src="/icon_person.svg"
                className="w-2 h-2 tablet:w-3 tablet:h-3 object-contain"
              />
              <p
                className="text-right font-medium text-black"
                style={{ fontSize: mobile ? "8px" : "11px" }}
              >
                {item.subscriber}
                {"/"}
                {item.capacity}
              </p>
            </div>
          </div>
        )}
        <div
          className={`flex flex-col w-full h-full items-start justify-start p-4 ${
            isExclusive ? "tablet:px-8 tablet:pb-8 tablet:pt-2" : "tablet:p-8"
          }`}
        >
          <div className={`flex flex-row justify-start items-center ${mobile ? "" : "-mt-6"} gap-1`}>
            <img
              alt="logo"
              src={
                item.uni_id === "EXPL0000"
                  ? item.organizer_logo === ""
                    ? item.logo_url
                    : item.organizer_logo
                  : item.logo_url
              }
              className="rounded-lg"
              style={{
                width: `${mobile ? "32.5px": "15.76px"}`,
                height: `${mobile ? "32.5px": "15.76px"}` 
              }}
            />
            <p
              className="text-left font-normal text-sm tablet:text-base text-black1"
              style={{
                lineHeight: `${mobile ? "24.8px" : "25px"}`,
                opacity: "0.88",
                fontSize: `${mobile ? "16px" : "10px"}`,
              }}
            >
              {item.uni_id === "EXPL0000"
                ? item.organizer_name === ""
                  ? item.university_name
                  : item.organizer_name
                : item.university_name}
            </p>
          </div>
          <p
            className={`text-left font-bold text-xl tablet:text-2xl text-black1 line-clamp-2 overflow-ellipsis ${mobile ? "h-11 tablet:h-11": "tablet:h-10"}`}
            style={{
              lineHeight: `${mobile ? "19.2px" : "19.2px"}`,
              marginTop: `${mobile ? "6px" : ""}`,
              fontSize: `${mobile ? "20px" : "16px"}`,
            }}
          >
            {item.topic}
          </p>
          <p
            className={`text-left text-base ${mobile ? "mt-2 h-5.5" : "-mt-1"} text-black1 line-clamp-1 overflow-ellipsis`}
            style={{
              lineHeight: "19px",
            }}
          >
            <span className="font-normal" style={{ fontSize: `${mobile ? "16px" : "10px"}` }}>Oleh:</span>
            <span className="font-bold" style={{ fontSize: `${mobile ? "16px" : "12px"}` }}> {item.lecturer}</span>
          </p>
          <div
            className={`flex flex-row items-center justify-start ${mobile ? "gap-2": "gap-1"}`}
            style={{ marginTop: `${mobile ? "6px" : ""}` }}
          >
            <img
              alt="calendar icon"
              src="/icon_calendar.svg"
              style={{
                width: `${mobile ? "16.25px" : "9.45px"}`,
                height: `${mobile ? "16.25px" : "8.59px"}`,
              }}
            />
            <p
              className="text-left font-normal text-sm tablet:text-base text-black1"
              style={{ opacity: "0.88", fontSize: `${mobile ? "16px" : "12px"}` }}
            >
              {moment(item.started_at)
                .tz("Asia/Jakarta")
                .format("DD MMMM YYYY")}
            </p>
          </div>
          <div className={`flex flex-row items-center justify-start ${mobile ? "gap-2": "gap-1"}`} style={{ marginTop: `${mobile ? "6px" : ""}` }}>
            <img
              alt="clock icon"
              src="/icon_clock.svg"
              style={{
                width: `${mobile ? "16.25px" : "9.45px"}`,
                height: `${mobile ? "16.25px" : "8.59px"}`,
              }}
            />
            <p
              className="text-left font-normal text-sm tablet:text-base text-black1"
              style={{ opacity: "0.88", fontSize: `${mobile ? "16px" : "12px"}` }}
            >
              {moment(item.started_at).tz("Asia/Jakarta").format("HH.mm")} WIB -{" "}
              {moment(item.ended_at).tz("Asia/Jakarta").format("HH.mm")} WIB
            </p>
          </div>
          {/* {item.is_past_classes === 0 && ( */}
          <div className="flex flex-col w-full">
            <div
              className={`w-full bg-gray7 ${mobile ? "mt-6" : "mt-3"}`}
              style={{
                minHeight: `${mobile ? "6.5px" : "3.15px"}`,
                maxHeight: `${mobile ? "6.5px" : "3.15px"}`,
                borderRadius: `${mobile ? "4.33px" : "2.1px"}`,
              }}
            >
              <div
                className={`${
                  occupancy === 0
                    ? "bg-correctOTP"
                    : occupancy > 0 && occupancy <= 0.3
                    ? "bg-correctOTP"
                    : occupancy > 0.3 && occupancy <= 0.6
                    ? "bg-mustard"
                    : occupancy > 0.6 && occupancy <= 1 && "bg-red2"
                } relative flex items-center justify-end`}
                style={{
                  minHeight: `${mobile ? "6.5px" : "3.15px"}`,
                  maxHeight: `${mobile ? "6.5px" : "3.15px"}`,
                  borderRadius: `${mobile ? "4.33px" : "2.1px"}`,
                  width: `${
                    item.is_past_classes === 1
                      ? "0%"
                      : occupancy === 0
                      ? "10%"
                      : `${occupancy * 100}%`
                  }`,
                }}
              >
                <img
                  alt="progressbar icon"
                  src="/icon_progressbar.svg"
                  className="w-5 h-5 absolute -right-4"
                />
              </div>
            </div>
            {item.is_past_classes === 0 && (
              <p
                className={`text-left font-bold text-xs tablet:text-base ${
                  occupancyStatus(occupancy) === "Lagi diminati"
                    ? "text-mustard"
                    : occupancyStatus(occupancy) === "Segera penuh 🔥"
                    ? "text-red2"
                    : occupancyStatus(occupancy) === "Penuh 🔥"
                    ? "text-red2"
                    : occupancyStatus(occupancy) === "Tersedia" &&
                      "text-correctOTP"
                }`}
                style={{ marginTop: `${mobile ? "10px" : "6px"}`, fontSize: `${mobile ? "13px" : "10px"}` }}
              >
                {occupancyStatus(occupancy)}
              </p>
            )}
          </div>
          {/* )} */}
        </div>
        <div className="flex flex-col tablet:flex-row w-full justify-start items-start tablet:items-center gap-3 absolute bottom-4 left-4 tablet:bottom-3 tablet:left-8">
          <div className="flex flex-row items-center justify-start gap-3">
            {item.is_subscribed === 1 && (
              <div
                className={`bg-tosca2 rounded-lg px-3 py-1 text-center font-medium text-base text-white`}
              >
                Terdaftar
              </div>
            )}
            <div
              className={`${
                item.is_past_classes === 1
                  ? "bg-gray6"
                  : item.is_online
                  ? "bg-mustard"
                  : "bg-tosca2"
              } rounded-lg px-3 py-1 text-center font-medium text-base text-white`}
              style={{ fontSize: `${mobile ? "16px" : "10px"}` }}
            >
              {item.is_past_classes === 1
                ? "Telah Usai"
                : item.is_online
                ? "Online"
                : "Offline"}
            </div>
          </div>
          {item.tmb_result_matched && (
            <div
              className={`bg-red2 rounded-lg px-3 py-1 text-center font-medium text-base text-white`}
            >
              Kamu banget ✨
            </div>
          )}
        </div>
      </button>
    </div>
  );
}
