import { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'

export default function FormFieldAutocomplete({ 
  id,
  title, 
  placeholder,
  value,
  options,
  isRequired,
  onSelect
}) {
  const [query, setQuery] = useState('')
  const [selectedOption, setSelectedOption] = useState(
    options.find(opt => opt.id === value) || null
  )
  const filteredOptions = query === ''
  ? []
  : options.filter((opt) =>
      opt.name
        .toLowerCase()
        .includes(query.toLowerCase())
    )

  return (
    <div className="w-full flex flex-col justify-start items-stretch gap-2">
      <p
        className="text-left font-medium text-base text-black"
        style={{ lineHeight: "12.33px" }}
      >
        {title}
      </p>
      <div className="w-full h-10 relative">
        <Combobox 
          value={selectedOption} 
          onChange={(option) => {
            setSelectedOption(option)
            onSelect(option.id)
          }}
        >
          <div className="relative h-full">
            <Combobox.Input
              className="h-full w-full"
              style={{
                border: "0.62px solid rgba(224, 224, 225, 1)",
                borderRadius: "24.66px",
                padding: "8px 16px 8px 16px",
                color: selectedOption ? "black" : "rgba(138, 138, 138, 1)",
              }}
              displayValue={(option) => option?.name}
              onChange={(event) => setQuery(event.target.value)}
              placeholder={placeholder}
            />
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Combobox.Options 
                className="w-full overflow-y-auto flex flex-col gap-2 p-2 rounded-xl bg-white absolute left-0 -bottom-2 z-10"
                style={{
                  transform: "translate(0, 100%)",
                  border: "0.62px solid rgba(224, 224, 225, 1)",
                  maxHeight: "140px",
                }}
              >
                {filteredOptions.length === 0 && query !== '' ? (
                    <div className="text-left text-gray-500">
                    Nothing found.
                    </div>
                ) : (
                    options
                    .filter(opt => opt.name.toLowerCase().includes(query.toLowerCase()))
                    .map((option) => (
                        <Combobox.Option
                        key={option.id}
                        value={option}
                        className="cursor-pointer"
                        >
                        {option.name}
                        </Combobox.Option>
                    ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    </div>
  )
}
