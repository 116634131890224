import { useRecoilValue } from "recoil";
import { useState, useEffect, Fragment, useRef } from "react";

import { authState } from "../../atoms/authState";
import { apiAnom, api, notifError } from "../../utils/api";
import ReactSelect from "react-select";
import * as _ from "lodash";
import Layout from "../../components/Layout";
import Button from "../../components/base/Button";
import Pagination from "../../components/pagination/Pagination";
import DropdownItem from "../../components/DropdownItem";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import Slider from "react-slick";
import PageLoading from "../PageLoading";
import HomeCardClassV2 from "../../components/HomeCardClassV2";
import Cookies from "js-cookie";
import LayoutV2 from "../../components/Layout_V2";
import Headerv2 from "../../components/Header_v2";

let pageSize = 12;

export default function ClassRoomV2() {
  const sliderRef = useRef();
  const scrl = useRef();
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const [currentPage, setCurrentPage] = useState(1);

  const [filterProvince, setFilterProvince] = useState(new Set());
  const [filterAcreditation, setFilterAcreditation] = useState(new Set());
  const [filterMajor, setFilterMajor] = useState(new Set());
  const [filterType, setFilterType] = useState(new Set());

  const [filterUniversities, setFilterUniversities] = useState(new Set());
  const [filterFOS, setFilterFOS] = useState(new Set());
  const [filterTipeKelas, setFilterTipeKelas] = useState(new Set());

  const [univData, setUnivData] = useState();
  const [classesData, setClassesData] = useState();
  const [topUnivs, setTopUnivs] = useState();
  const [classNames, setClassNames] = useState();
  const [classSuggestion, setClassSuggestion] = useState(false);
  const [keyword, setKeyword] = useState();
  const [keyword2, setKeyword2] = useState("");
  const [listProvince, setListProvince] = useState([]);
  const [favList, setFavList] = useState([]);
  const [majorsList, setMajorsList] = useState([]);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [currentSliderPage, setCurrentSliderPage] = useState(1);
  const [univList, setUnivList] = useState([]);
  const [fosList, setFosList] = useState([]);
  const [popularClasses, setPopularClasses] = useState([]);
  const [tagKelas, setTagKelas] = useSearchParams();
  const tagId = tagKelas.get("tag");
  const [tagIdParam, setTagIdParam] = useState();
  const filterDivRef = useRef();

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    document.title = "Explore Kelas";
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var screenWidth = windowSize.innerWidth;
  var screenHeight = windowSize.innerHeight;

  const favUnivSetting = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    draggable: false,
    swipe: false,
    autoplay: false,
    arrows: false,
    speed: 300,
    // autoplaySpeed: 1000,
    easing: "linear",
  };

  useEffect(() => {
    const getUniveristy = () => {
      _api
        .get("/universities")
        .then((res) => {
          setUnivList(
            res.data.data.map((item) => {
              return {
                id: item.id,
                name: item.name,
              };
            })
          );
        })
        .catch((_) => {
          setUnivData([]);
        });
    };
    const getFieldOfStudy = () => {
      _api
        .get("/field-of-study")
        .then((res) => {
          setFosList(res.data.data);
        })
        .catch((err) => {
          notifError(err);
          setFosList([]);
        });
    };
    const getPopularClasses = () => {
      _api
        .get("/classes/popular")
        .then((res) => {
          setPopularClasses(res.data.data.popular);
        })
        .catch(notifError);
    };
    getUniveristy();
    getFieldOfStudy();
    getPopularClasses();
    {
      /* USE BELOW CODE IF YOUT WANT TO AUTOSCROLL TO LIST SECTION AFTER CLICKING TAG IN CLASS DETAIL*/
    }
    // setTimeout(() => {
    //   if (tagId) {
    //     filterDivRef.current.scrollIntoView({ behavior: "smooth" });
    //   }
    // }, 300);
  }, []);

  useEffect(() => {
    let tagIdNumber = null;

    if (tagId !== null) {
      tagIdNumber = parseInt(tagId);
      setTagIdParam(Cookies.get("tagKelas"));
    }

    let arrayClassType =
      Array.from(filterTipeKelas).map((item) => item.value) ?? [];
    let filterClassOnline = -1;
    let filterClassUpcoming = -1;

    // Check for "Online" and "Offline"
    if (
      arrayClassType.includes("Online") &&
      arrayClassType.includes("Offline")
    ) {
      filterClassOnline = -1;
    } else if (arrayClassType.includes("Online")) {
      filterClassOnline = 1;
    } else if (arrayClassType.includes("Offline")) {
      filterClassOnline = 0;
    }

    // Check for "Mendatang" and "Telah Usai"
    if (
      arrayClassType.includes("Mendatang") &&
      arrayClassType.includes("Telah Usai")
    ) {
      filterClassUpcoming = -1;
    } else if (arrayClassType.includes("Mendatang")) {
      filterClassUpcoming = 0;
    } else if (arrayClassType.includes("Telah Usai")) {
      filterClassUpcoming = 1;
    }
    const getUpcoming = () => {
      _api
        .post("/classes/upcoming", {
          university_ids:
            Array.from(filterUniversities, (item) => item.id) ?? [],
          keyword: keyword2,
          page: currentPage,
          limit: pageSize,
          field_of_study_ids: Array.from(filterFOS, (item) => item.id) ?? [],
          sort_direction: "DESC",
          sort_key: "availability",
          is_online: filterClassOnline, //Status: ALL = -1, True = 1, False = 0
          is_past_classes: filterClassUpcoming, //Status: ALL = -1, True = 1, False = 0
          tags: tagIdNumber ? [tagIdNumber] : [],
        })
        .then((res) => {
          setClassesData(res.data.data);
          setClassNames(res.data.data.upcoming.map((item) => item.topic));
        })
        .catch((err) => {
          notifError(err);
          setClassesData([]);
        });
    };
    getUpcoming();
  }, [
    keyword2,
    filterUniversities,
    filterFOS,
    currentPage,
    filterTipeKelas,
    tagKelas,
  ]);

  const styles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      backgroundColor: "white",
      borderColor: state.isFocused ? "white" : "white",
      "&:hover": {
        borderColor: "white",
      },
      textAlign: "left",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderColor: state.isFocused ? "white" : "white",
      "&:hover": {
        borderColor: "white",
      },
      textAlign: "left",
    }),
    input: (provided, state) => ({
      ...provided,
      "input:focus": {
        boxShadow: "none",
      },
      input: {
        opacity: "1 !important",
      },
      borderColor: state.isFocused ? "white" : "white",
      "&:hover": {
        borderColor: "white",
      },
      textAlign: "left",
    }),
    placeholder: (provided) => ({
      ...provided,
      textAlign: "left",
      fontWeight: "400",
      fontSize: "14px",
    }),
  };

  const onDeleteUni = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    const universities = new Set(filterUniversities);
    universities.delete(item);
    setFilterUniversities(universities);
    setCurrentPage(1);
  };

  const onDeleteFos = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    const fos = new Set(filterFOS);
    fos.delete(item);
    setFilterFOS(fos);
    setCurrentPage(1);
  };

  const onDeleteTipeKelas = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    const tipe = new Set(filterTipeKelas);
    tipe.delete(item);
    setFilterTipeKelas(tipe);
    setTagKelas("");
    Cookies.remove("tagKelas");
    setCurrentPage(1);
  };

  function handleFilterUniMobile(e, item) {
    const checked = e.currentTarget.checked;
    const universities = new Set(filterUniversities);
    if (checked) {
      universities.add(item);
    } else {
      universities.delete(item);
    }
    setFilterUniversities(universities);
    setCurrentPage(1);
  }

  function handleFilterFosMobile(e, item) {
    const checked = e.currentTarget.checked;
    const fos = new Set(filterFOS);
    if (checked) {
      fos.add(item);
    } else {
      fos.delete(item);
    }
    setFilterFOS(fos);
    setCurrentPage(1);
  }

  function handleFilterTipeKelasMobile(e, item) {
    const checked = e.currentTarget.checked;
    const tipe = new Set(filterTipeKelas);
    if (checked) {
      tipe.add(item);
    } else {
      tipe.delete(item);
    }
    setFilterTipeKelas(tipe);
    setCurrentPage(1);
  }

  const onDeleteTagKelas = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setTagKelas("");
    Cookies.remove("tagKelas");
    setCurrentPage(1);
  };

  const classTesimonies = [
    {
      testimony: `"Melalui kelas Exploration yang pernah aku ikutin, selain dapet e-certificate aku juga bisa punya gambaran tentang universitas yang open trial class dan pembahasan soal jurusan-jurusan yang menarik. Bahkan beberapa univ ada yang datengin field workernya! Keren banget"`,
      name: "Kevin Harvindra",
      image:
        "https://strgonelabsprod.blob.core.windows.net/explorationid/Foto%20Siswa%20Testimoni/Kevin%20Harvindra.png",
      school: "SMA Xaverius 1 Palembang",
    },
    {
      testimony: `"Kelas-kelas percobaan dan bonus e-certificate lumayan banget untuk bahan pertimbangan, pengalaman, pengetahuan soal jurusan dan dunia perkuliahan. Fix bgt si ini siswa/i kelas 10-12 harus cobain. Fitur-fiturnya menarik, tampilan kece, mantepp deh"`,
      name: "Kayla Nurul Tahira",
      image:
        "https://strgonelabsprod.blob.core.windows.net/explorationid/Foto%20Siswa%20Testimoni/Kayla%20Nurul%20Tahira.png",
      school: "SMAN 1 Batujajar",
    },
    {
      testimony: `"Kelas Exploration menawarkan materi menarik dari jurusan di perkuliahan, disampaikan dengan simpel jadi buat orang awam mudah banget untuk dipahami."`,
      name: "Ryuzo Gunawan",
      image:
        "https://strgonelabsprod.blob.core.windows.net/explorationid/Foto%20Siswa%20Testimoni/Ryuzo%20Gunawan.png",
      school: "SMAK St. Louis 1 Surabaya",
    },
  ];

  const tipeKelas = [
    { value: "Online", label: "Online" },
    { value: "Offline", label: "Offline" },
    { value: "Mendatang", label: "Mendatang" },
    { value: "Telah Usai", label: "Telah Usai" },
  ];

  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift); // Updates the latest scrolled postion

    //For checking if the scroll has ended
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //This will check scroll event and checks for scroll end
  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
  const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling

  useEffect(() => {
    //Check width of the scollings
    if (
      scrl.current &&
      scrl?.current?.scrollWidth === scrl?.current?.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
    return () => {};
  }, [scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]);

  if (!classesData) {
    return <PageLoading />;
  }

  return (
    <LayoutV2 padding="0px">
      {/* Search & Filter Bar */}
      <div className="flex flex-row w-full gap-6 py-3 px-6 tablet:px-16 mac:px-36 fhd:px-110 bg-white border border-gray-200 rounded shadow-md transition duration-300 ease-in-out hover:shadow-lg sticky top-0 z-30">
        <form className="relative flex flex-row remove-input-txt-border w-full tablet:gap-5">
          <label className="flex flex-row items-center w-1/3">
            <div className="pr-2">
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.1115 0C4.80323 0 0.5 4.20819 0.5 9.39926C0.5 14.5903 4.80323 18.7985 10.1115 18.7985C12.3819 18.7985 14.4684 18.0287 16.113 16.7415L19.2371 19.7886L19.3202 19.8586C19.6102 20.0685 20.0214 20.0446 20.2839 19.7873C20.5726 19.5043 20.572 19.0459 20.2825 18.7636L17.1952 15.7523C18.7649 14.0794 19.7231 11.8487 19.7231 9.39926C19.7231 4.20819 15.4198 0 10.1115 0ZM10.1115 1.44774C14.6022 1.44774 18.2426 5.00776 18.2426 9.39926C18.2426 13.7908 14.6022 17.3508 10.1115 17.3508C5.62086 17.3508 1.98044 13.7908 1.98044 9.39926C1.98044 5.00776 5.62086 1.44774 10.1115 1.44774Z"
                  fill="#171717"
                />
              </svg>
            </div>
            <input
              type="text"
              className="flex-1 form-input border-none pb-4 tablet:rounded-none"
              style={{
                maxHeight: "38px",
                borderBottom: screenWidth < 1024 && "1px solid grey",
                borderRight: screenWidth >= 1024 && "1px solid grey",
              }}
              placeholder="Cari nama kelas"
              value={keyword2}
              onChange={(e) => {
                setKeyword2(e.target.value);
                setClassSuggestion(true);
              }}
            />
          </label>
          {classSuggestion && keyword2.length > 0 && (
            <div className="absolute z-10 top-10 left-0 flex flex-col items-start w-full overflow-auto shadow-lg px-0 tablet:px-8 bg-white">
              {classNames.map((item, index) => (
                <Button
                  size="small"
                  variant="link"
                  key={`univ ${index}`}
                  className="font-normal text-sm text-black1 text-left w-full py-3 justify-start"
                  onClick={() => {
                    setClassSuggestion(false);
                    setKeyword(item);
                  }}
                >
                  {item}
                </Button>
              ))}
            </div>
          )}
          {screenWidth >= 1024 && (
            <div
              className="flex flex-row w-2/3 relative z-80"
              style={{ right: "-12px" }}
            >
              <div className="flex flex-1 flex-col w-max items-start justify-center">
                <ReactSelect
                  styles={styles}
                  options={univList}
                  isMulti
                  isClearable={false}
                  isSearchable={false}
                  controlShouldRenderValue={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  menuColor
                  getOptionLabel={(data) => data["name"]}
                  getOptionValue={(data) => data["id"]}
                  components={{
                    Option: DropdownItem,
                  }}
                  className="w-full border-none"
                  placeholder="Partner Kelas"
                  onChange={(v) => {
                    const uni = new Set(v);
                    setFilterUniversities(uni);
                    setCurrentPage(1);
                  }}
                  value={Array.from(filterUniversities)}
                />
              </div>
              <div className="flex flex-1 flex-col w-max items-start justify-center pl-4">
                <ReactSelect
                  styles={styles}
                  options={fosList}
                  isMulti
                  isClearable={false}
                  isSearchable={false}
                  controlShouldRenderValue={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  menuColor
                  getOptionLabel={(data) => data["name"]}
                  getOptionValue={(data) => data["id"]}
                  components={{
                    Option: DropdownItem,
                  }}
                  className="w-full border-none"
                  placeholder="Bidang Studi"
                  onChange={(v) => {
                    const fos = new Set(v);
                    setFilterFOS(fos);
                    setCurrentPage(1);
                  }}
                  value={Array.from(filterFOS)}
                />
              </div>
              <div className="flex flex-1 flex-col w-max items-start justify-center pl-4">
                <ReactSelect
                  styles={styles}
                  options={tipeKelas}
                  isMulti
                  isClearable={false}
                  isSearchable={false}
                  controlShouldRenderValue={false}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  menuColor
                  getOptionLabel={(data) => data.label}
                  getOptionValue={(data) => data.value}
                  components={{
                    Option: DropdownItem,
                  }}
                  className="w-full border-none"
                  placeholder="Tipe kelas"
                  onChange={(v) => {
                    const tipe = new Set(v);
                    setFilterTipeKelas(tipe);
                    setCurrentPage(1);
                  }}
                  value={Array.from(filterTipeKelas)}
                />
              </div>
            </div>
          )}
        </form>
        {screenWidth < 1024 && (
          <div className="flex items-center ">
            <button
              type="button"
              onClick={() => {
                openModal();
                setFilterUniversities([]);
                setFilterProvince([]);
                setFilterAcreditation([]);
                setFilterType([]);
              }}
              className="pr-6"
            >
              <img src="/filter-icon.svg" />
            </button>
            <Transition appear show={isOpen} as={Fragment}>
              <Dialog as="div" className="relative z-50" onClose={closeModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    className="fixed inset-0"
                    style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
                  />
                </Transition.Child>
                <div
                  className="fixed inset-0 overflow-y-auto"
                  style={{ zIndex: 999, marginTop: "64px" }}
                >
                  <div className="flex min-h-full w-full items-start justify-center p-4 tablet:px-56 tablet:py-px text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel
                        className="flex w-screen min-h-screen transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all"
                        style={{
                          height: "calc(100vh - 64px)",
                          maxHeight: "calc(100vh - 64px)",
                          position: "relative",
                          zIndex: 1000,
                        }}
                      >
                        <div
                          className="relative flex min-h-screen w-full flex-col overflow-y-auto bg-white"
                          style={{
                            minHeight: screenHeight - 20,
                            maxHeight: screenHeight - 20,
                          }}
                        >
                          <div className="flex flex-col w-full p-4 h-4/5 overflow-y-auto">
                            <div className="flex flex-col w-full p-2">
                              <button type="button" onClick={closeModal}>
                                <MdClose
                                  size={24}
                                  color="rgba(0, 0, 0, 0.54)"
                                />
                              </button>
                              <div className="flex flex-col gap-4 divide-y-2 mt-10 overflow-y-auto">
                                <div className="w-full items-start justify-center">
                                  <p className="font-bold text-base text-black1 text-left pb-2">
                                    Partner kampus
                                  </p>
                                  <Listbox value={filterUniversities ?? []}>
                                    <div className="flex flex-col">
                                      <Listbox.Button>
                                        <div className="flex flex-row items-center">
                                          <p className="flex-1 font-normal text-sm text-gray1 text-left">
                                            Pilih kampus penyelenggara kelas
                                          </p>
                                          <ChevronDownIcon
                                            className="w-5 h-5"
                                            style={{
                                              color: "rgba(23, 23, 23, 1)",
                                            }}
                                          />
                                        </div>
                                      </Listbox.Button>
                                      <Transition
                                        as={Fragment}
                                        unmount={false}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options
                                          static
                                          className="h-46 overflow-y-auto mt-2"
                                        >
                                          {univList.map((item, index) => (
                                            <div
                                              key={index}
                                              className="flex flex-row items-center gap-2 mb-4"
                                            >
                                              <input
                                                id={item.id}
                                                type="checkbox"
                                                value={item.name}
                                                onChange={(e) => {
                                                  handleFilterUniMobile(
                                                    e,
                                                    item
                                                  );
                                                }}
                                                className="w-4 h-4 text-primaryColor bg-gray-100 border-gray-300 rounded focus:primaryColor dark:focus:primaryColor dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                              />
                                              <label
                                                htmlFor={item.name}
                                                className="text-left font-normal text-sm text-black 1"
                                                style={{
                                                  lineHeight: "22px",
                                                  opacity: "0.6",
                                                }}
                                              >
                                                {item.name}
                                              </label>
                                            </div>
                                          ))}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </Listbox>
                                </div>
                                <div className="w-full items-start justify-center pt-4">
                                  <p className="font-bold text-base text-black1 text-left pb-2">
                                    Bidang Studi
                                  </p>
                                  <Listbox value={filterFOS ?? []}>
                                    <div className="flex flex-col">
                                      <Listbox.Button>
                                        <div className="flex flex-row items-center">
                                          <p className="flex-1 font-normal text-sm text-gray1 text-left">
                                            Pilih kelas sesuai bidang studi
                                          </p>
                                          <ChevronDownIcon
                                            className="w-5 h-5"
                                            style={{
                                              color: "rgba(23, 23, 23, 1)",
                                            }}
                                          />
                                        </div>
                                      </Listbox.Button>
                                      <Transition
                                        as={Fragment}
                                        unmount={false}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options
                                          static
                                          className="h-46 overflow-y-auto mt-2"
                                        >
                                          {fosList.map((item, index) => {
                                            return (
                                              <div
                                                key={index}
                                                className="flex flex-row gap-2 items-center mb-4"
                                              >
                                                <input
                                                  id={item.name}
                                                  type="checkbox"
                                                  value={item.id}
                                                  onChange={(e) => {
                                                    handleFilterFosMobile(
                                                      e,
                                                      item
                                                    );
                                                  }}
                                                  className="w-4 h-4 text-primaryColor bg-gray-100 border-gray-300 rounded focus:primaryColor dark:focus:primaryColor dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                ></input>
                                                <label
                                                  htmlFor={item.name}
                                                  className="text-left font-normal text-sm text-black 1"
                                                  style={{
                                                    lineHeight: "22px",
                                                    opacity: "0.6",
                                                  }}
                                                >
                                                  {item.name}
                                                </label>
                                              </div>
                                            );
                                          })}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </Listbox>
                                </div>
                                <div className="w-full items-start justify-center pt-4">
                                  <p className="font-bold text-base text-black1 text-left pb-2">
                                    Tipe kelas
                                  </p>
                                  <Listbox value={filterTipeKelas ?? []}>
                                    <div className="flex flex-col">
                                      <Listbox.Button>
                                        <div className="flex flex-row items-center">
                                          <p className="flex-1 font-normal text-sm text-gray1 text-left">
                                            Pilih tipe kelas
                                          </p>
                                          <ChevronDownIcon
                                            className="w-5 h-5"
                                            style={{
                                              color: "rgba(23, 23, 23, 1)",
                                            }}
                                          />
                                        </div>
                                      </Listbox.Button>
                                      <Transition
                                        as={Fragment}
                                        unmount={false}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options
                                          static
                                          className="h-46 overflow-y-auto mt-2"
                                        >
                                          {tipeKelas.map((item, index) => {
                                            return (
                                              <div
                                                key={index}
                                                className="flex flex-row gap-2 items-center mb-4"
                                              >
                                                <input
                                                  id={item.label}
                                                  type="checkbox"
                                                  value={item.value}
                                                  onChange={(e) => {
                                                    handleFilterTipeKelasMobile(
                                                      e,
                                                      item
                                                    );
                                                  }}
                                                  className="w-4 h-4 text-primaryColor bg-gray-100 border-gray-300 rounded focus:primaryColor dark:focus:primaryColor dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                ></input>
                                                <label
                                                  htmlFor={item.value}
                                                  className="text-left font-normal text-sm text-black 1"
                                                  style={{
                                                    lineHeight: "22px",
                                                    opacity: "0.6",
                                                  }}
                                                >
                                                  {item.value}
                                                </label>
                                              </div>
                                            );
                                          })}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </Listbox>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="absolute flex flex-col w-full bottom-10 px-6 bg-white">
                            <button
                              onClick={closeModal}
                              className="flex flex-col items-center justify-center h-12 rounded-lg bg-primaryColor"
                            >
                              <p className="font-semibold text-base text-center text-white">
                                Terapkan filter pencarian
                              </p>
                            </button>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          </div>
        )}
      </div>
      <div className="flex flex-col w-full items-center tablet:px-18 mt-7 tablet:mt-5">
        {popularClasses.length === 0 ? (
          <div
            className="w-full"
            style={{ padding: screenWidth < 1024 && "0px 32.05px 0px 33px" }}
          >
            <div className="w-full flex justify-start tablet:items-end relative">
              <p
                className="text-left font-bold text-black"
                style={{
                  maxWidth: screenWidth < 1024 && "214px",
                  fontSize: screenWidth < 1024 ? "26px" : "36px",
                  lineHeight: screenWidth < 1024 ? "28.08px" : "38.88px",
                  letterSpacing: screenWidth < 12024 ? "-0.87px" : "-0.71px",
                }}
              >
                Yuk ikuti berbagai kelas menarik
              </p>
              <img
                style={{
                  position: screenWidth < 1024 && "absolute",
                  top: "0.06px",
                  right: "48.12px",
                  marginLeft: screenWidth >= 1024 && "26px",
                }}
                src={
                  screenWidth < 1024
                    ? "/illu_redv3_mobile_book.svg"
                    : "/illu_redv3_book.svg"
                }
              />
              <img
                style={{
                  position: screenWidth < 1024 && "absolute",
                  top: "2.36px",
                  right: "-12.05px",
                  marginLeft: screenWidth >= 1024 && "2.66px",
                }}
                src={
                  screenWidth < 1024
                    ? "/illu_purple_and_red_mobile_pencil.svg"
                    : "/illu_purple_and_red_pencil.svg"
                }
              />
            </div>
          </div>
        ) : (
          <div
            className={`relative flex flex-col w-screen ${
              popularClasses.length > 0 &&
              `${
                screenWidth < 1024 ? "h-max pt-22 pb-22" : "h-max pt-12 pb-22"
              }`
            } tablet:p-0 ${popularClasses.length > 0 ? "mt-0" : "mt-24"}`}
            style={{ backgroundColor: "#F3CC3C" }}
          >
            {popularClasses.length > 0 && (
              <img
                className="absolute w-10/12 h-full top-0 right-0 tablet:-right-10 object-cover"
                src={`${
                  screenWidth < 1024
                    ? "/background_classexplore_1.svg"
                    : "/background_classexplore_1_2.svg"
                }`}
              />
            )}
            {popularClasses.length > 0 && (
              <img
                className={`${
                  screenWidth < 1024
                    ? "absolute top-0 right-22 mt-2 tablet:h-20 tablet:top-6"
                    : "absolute top-0 right-0 -mt-4 mr-10 tablet:h-20 tablet:right-1/2 tablet:top-6"
                }`}
                src={
                  screenWidth < 1024
                    ? "/illu_redv3_mobile_book.svg"
                    : "/illu_redv3_book.svg"
                }
              />
            )}
            {popularClasses.length > 0 && (
              <img
                className={`${
                  screenWidth < 1024
                    ? "absolute top-0 right-7 mt-2 tablet:h-20 tablet:right-1/2 tablet:top-6"
                    : "absolute top-0 right-0 -mt-4 -ml-8 -mr-16 tablet:h-20 tablet:right-1/2 tablet:top-6"
                }`}
                src={
                  screenWidth < 1024
                    ? "/illu_purple_and_red_mobile_pencil.svg"
                    : "/illu_purple_and_red_pencil.svg"
                }
              />
            )}
            {popularClasses.length > 0 && (
              <div className="relative flex flex-col tablet:px-20 z-10">
                <div className="flex flex-row justify-between w-full tablet:mt-20">
                  <p
                    className="text-left font-bold text-2xl tablet:text-36px text-black tracking-0.8px pl-4 tablet:pl-0 -mt-15 tablet:-mt-18"
                    style={{
                      lineHeight: `${screenWidth < 1024 ? "26px" : "52px"}`,
                    }}
                  >
                    Kelas paling diminati
                  </p>
                  {screenWidth >= 1024 && popularClasses.length > 3 && (
                    <div className="flex flex-row gap-2 items-center justify-end">
                      <div className="flex flex-row items-center justify-end text-base mac:text-xl text-right text-white font-normal tracking-wide mr-10">
                        <p>{currentSliderPage}</p>
                        <p>{`/2`}</p>
                        {/* <p>{`/${Math.ceil(topUnivs?.length / 3)}`}</p> */}
                      </div>
                      <button
                        onClick={() => {
                          setCurrentSliderPage(currentSliderPage - 1);
                          sliderRef.current.slickPrev();
                        }}
                        disabled={currentSliderPage === 1}
                        className={`bg-white rounded-lg p-3 ${
                          currentSliderPage === 1 ? "opacity-30" : "opacity-100"
                        }`}
                      >
                        <ArrowLeftIcon
                          className="w-4 h-4 mac:w-5 mac:h-5"
                          color="rgba(23, 23, 23, 0.4)"
                        />
                      </button>
                      <button
                        onClick={() => {
                          setCurrentSliderPage(currentSliderPage + 1);
                          sliderRef.current.slickNext();
                        }}
                        disabled={currentSliderPage === 2}
                        className={`bg-white rounded-lg p-3 ${
                          currentSliderPage === 2 ? "opacity-30" : "opacity-100"
                        }`}
                      >
                        <ArrowRightIcon
                          className="w-4 h-4 mac:w-5 mac:h-5"
                          color="rgba(23, 23, 23, 0.4)"
                        />
                      </button>
                    </div>
                  )}
                </div>
                {screenWidth < 1024 ? (
                  <div className="flex flex-row w-full gap-6 justify-start items-center overflow-x-auto mt-8 ml-4 pr-10">
                    {popularClasses.slice(0, 6).map((item, index) => {
                      return (
                        <div key={index}>
                          <HomeCardClassV2
                            key={index}
                            item={item}
                            mobile={screenWidth < 1024 ? true : false}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="w-full overflow-hidden">
                    {popularClasses.length > 3 ? (
                      <Slider ref={sliderRef} {...favUnivSetting}>
                        {popularClasses.slice(0, 6).map((item, index) => {
                          return (
                            <div key={index}>
                              <HomeCardClassV2
                                key={index}
                                item={item}
                                mobile={screenWidth < 1024 ? true : false}
                                popular={true}
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    ) : (
                      <div className="flex flex-row justify-start items-start gap-6 w-full overflow-hidden">
                        {popularClasses.slice(0, 6).map((item, index) => {
                          return (
                            <div key={index}>
                              <HomeCardClassV2
                                key={index}
                                item={item}
                                mobile={screenWidth < 1024 ? true : false}
                                popular={true}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div
          className="flex flex-col w-full tablet:w-4/5 items-start mb-0 px-4 tablet:px-12"
          style={{ marginTop: screenWidth < 1024 ? "24px" : "22px" }}
        >
          {/* Filter Uni */}
          {Array.from(filterUniversities).length > 0 && (
            <div className="px-2 flex flex-row flex-wrap items-center">
              <p className="text-xs text-black mr-3">Kampus Penyelenggara:</p>
              {Array.from(filterUniversities).map((item, index) => (
                <span
                  key={item["id"].toString()}
                  className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900"
                >
                  <span>{item["name"]}</span>
                  <button
                    type="button"
                    className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    onClick={(e) => onDeleteUni(e, item)}
                  >
                    <span className="sr-only">Remove filter </span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          )}
          {/* Filter Field of Study */}
          {Array.from(filterFOS).length > 0 && (
            <div className="px-2 flex flex-row flex-wrap items-center">
              <p className="text-xs text-black mr-3">Bidang Studi:</p>
              {Array.from(filterFOS).map((item, index) => (
                <span
                  key={item["id"].toString()}
                  className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900"
                >
                  <span>{item["name"]}</span>
                  <button
                    type="button"
                    className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    onClick={(e) => onDeleteFos(e, item)}
                  >
                    <span className="sr-only">Remove filter </span>
                    <svg
                      className="h-2 w-2"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 8 8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeWidth="1.5"
                        d="M1 1l6 6m0-6L1 7"
                      />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          )}
          {/* Filter Tipe Kelas */}
          <div className="flex flex-row w-full">
            {Array.from(filterTipeKelas).length > 0 && (
              <div className="px-2 flex flex-row flex-wrap items-center">
                <p className="text-xs text-black mr-3">Tipe Kelas:</p>
                {Array.from(filterTipeKelas).map((item, index) => (
                  <span
                    key={index}
                    className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900"
                  >
                    <span>{item["label"]}</span>
                    <button
                      type="button"
                      className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                      onClick={(e) => onDeleteTipeKelas(e, item)}
                    >
                      <span className="sr-only">Remove filter </span>
                      <svg
                        className="h-2 w-2"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 8 8"
                      >
                        <path
                          strokeLinecap="round"
                          strokeWidth="1.5"
                          d="M1 1l6 6m0-6L1 7"
                        />
                      </svg>
                    </button>
                  </span>
                ))}
              </div>
            )}
            {tagId !== null && (
              <div className="m-0.5 inline-flex rounded-md border border-gray-200 items-center px-2 py-1 text-xs bg-white text-gray-900">
                {/* <span> {` ${tagLabel(tagId)}`}</span> */}
                <span> {`${tagIdParam}`}</span>
                <button
                  type="button"
                  className="flex-shrink-0 ml-1 h-4 w-4 p-1 rounded-sm inline-flex text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                  onClick={(e) => onDeleteTagKelas(e)}
                >
                  <span className="sr-only">Remove filter </span>
                  <svg
                    className="h-2 w-2"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 8 8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeWidth="1.5"
                      d="M1 1l6 6m0-6L1 7"
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
        </div>
        {classesData.upcoming.length < 1 && (
          <div className="flex py-10 justify-center">
            <div className="text-center">
              <h3 className="text-gray-800 text-2xl">
                Pencarian data tidak <br />
                ditemukan
              </h3>
            </div>
          </div>
        )}
        <div className="pb-4 flex flex-col justify-start items-center tablet:grid tablet:grid-cols-4 gap-6 tablet:justify-center mt-4 tablet:mt-4">
          {classesData.upcoming.map((item, index) => {
            return (
              <HomeCardClassV2
                key={index}
                item={item}
                mobile={screenWidth < 1024 ? true : false}
              />
            );
          })}
        </div>
        <Pagination
          currentPage={currentPage}
          totalCount={classesData.metadata.total_item}
          pageSize={classesData.metadata.limit}
          onPageChange={(page) => setCurrentPage(page)}
        />
        {/* Class Testimony Section */}
        {/* <div className="flex flex-col w-full py-8 px-10 tablet:px-0">
          <div className="flex flex-col tablet:flex-row tablet:justify-between w-full">
            <div className="flex flex-col w-full gap-3 tablet:gap-6">
              <p
                className="text-left font-bold text-2xl tablet:text-48px text-black1 tracking-0.8px"
                style={{
                  lineHeight: `${screenWidth < 1024 ? "26px" : "52px"}`,
                }}
              >
                Apa kata mereka tentang Explore Kelas?
              </p>
              <p
                className="text-left font-normal text-base tablet:text-2xl text-gray-500"
                style={{
                  lineHeight: `${screenWidth < 1024 ? "19px" : "26px"}`,
                }}
              >
                Yuk dengarkan testimoni dari para peserta Explore Kelas!
              </p>
            </div>
            {screenWidth >= 1024 && classTesimonies.length > 3 && (
              <div className="flex flex-row gap-2 items-center justify-end mr-20">
                <button
                  onClick={() => slide(-200)}
                  disabled={scrollX === 0}
                  className={`bg-tosca2 rounded-lg p-3 ${
                    scrollX === 0 ? "opacity-30" : "opacity-100"
                  }`}
                >
                  <ArrowLeftIcon
                    className="w-4 h-4 mac:w-5 mac:h-5"
                    color="white"
                  />
                </button>
                <button
                  onClick={() => slide(+200)}
                  disabled={scrolEnd}
                  className={`bg-tosca2 rounded-lg p-3 ${
                    scrolEnd ? "opacity-30" : "opacity-100"
                  }`}
                >
                  <ArrowRightIcon
                    className="w-4 h-4 mac:w-5 mac:h-5"
                    color="white"
                  />
                </button>
              </div>
            )}
          </div>
          <div
            ref={scrl}
            onScroll={scrollCheck}
            className="flex flex-row overflow-x-auto gap-4 tablet:gap-6 pr-4 tablet:mt-10"
            style={{ scrollBehavior: "smooth" }}
          >
            {classTesimonies.map((item, index) => (
              <div key={index} className="py-6 tablet:py-10">
                <div
                  key={index}
                  className="p-6 tablet:p-8 rounded-xl shadow-xl"
                  style={{
                    minWidth: `${screenWidth < 1024 ? "300px" : "410px"}`,
                    maxWidth: `${screenWidth < 1024 ? "300px" : "410px"}`,
                    minHeight: `${screenWidth < 1024 ? "208px" : "238px"}`,
                    maxHeight: `${screenWidth < 1024 ? "208px" : "238px"}`,
                    // boxShadow: "24px 24px 200px rgba(23, 23, 23, 0.12)",
                  }}
                >
                  <p
                    className="text-left font-normal text-sm tablet:text-base text-black1 h-22 tablet:h-26 line-clamp-4 overflow-ellipsis"
                    style={{
                      lineHeight: `${screenWidth < 1024 ? "22px" : "25px"}`,
                    }}
                  >
                    {item.testimony}
                  </p>
                  <div className="flex flex-row items-center justify-start w-full mt-6 gap-4">
                    <img
                      src={item.image}
                      className="bg-white w-10 h-10 object-fill rounded-full"
                    />
                    <div className="flex flex-col items-start justify-center gap-1 tablet:gap-0">
                      <p
                        className="text-left font-bold text-sm tablet:text-base text-black1"
                        style={{
                          lineHeight: `${screenWidth < 1024 ? "22px" : "25px"}`,
                        }}
                      >
                        {item.name}
                      </p>
                      <p
                        className="text-left font-normal text-sm tablet:text-base text-black1"
                        style={{
                          lineHeight: `${screenWidth < 1024 ? "21px" : "24px"}`,
                          opacity: "0.88",
                        }}
                      >
                        {item.school}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </LayoutV2>
  );
}
