export default function ClassFacilities({ item, index, mobile }) {
  return (
    <div
      className="flex flex-col justify-start items-stretch gap-2 tablet:gap-4 w-max bg-white rounded-xl"
      style={{
        minWidth: mobile ? "249.17px" : "253px",
        maxWidth: mobile ? "249.17px" : "253px",
        height: mobile ? "132.17px" : "174px",
        padding:
          mobile < 1024
            ? "26px 26px 12.5px 26px"
            : "28.44px 48.89px 28.44px 28.44px",
        gap: mobile ? "8px" : "14.22px",
      }}
    >
      <p
        className="w-full text-left font-medium tablet:font-bold text-black1 line-clamp-1 overflow-ellipsis"
        style={{
          fontSize: mobile < 1024 ? "20px" : "18px",
          lineHeight: mobile < 1024 ? "25px" : "22.5px",
        }}
      >
        {item.name}
      </p>
      <p
        className={`w-full text-left font-normal text-base text-black188 ${
          mobile ? "line-clamp-3" : "line-clamp-4"
        } overflow-ellipsis`}
        style={{ lineHeight: "20px" }}
      >
        {item.description}
      </p>
    </div>
  );
}
