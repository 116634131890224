import { Fragment, useEffect, useRef, useState } from "react";
import ButtonV2 from "../components/ButtonV2";
import FormField from "../components/FormField";
import LayoutV2 from "../components/Layout_V2";
import SignInCarouselSlides from "../components/SignInCarouselSlides";
import Slider from "react-slick";
import { apiAnom, notifError } from "../utils/api";
import { Dialog, Transition } from "@headlessui/react";
import charCardInfo from "./CharCardInfo.json";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function B2BEmailCheck() {
  const navigate = useNavigate();
  const charCardsRef = useRef();
  let [isOpen, setIsOpen] = useState(false);
  let [userEmail, setUserEmail] = useState();
  const params = new URLSearchParams(window.location.search);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: params.get("username")
  });

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    apiAnom
      .post("/student-b2b/check-email", {
        email: e.target[0].value.toLowerCase(),
      })
      .then((res) => {
        if (res.data.data.is_email_exist) {
          setIsOpen(true);
        } else {
          navigate(
            `/change-password-b2b/user?username=${encodeURIComponent(
              params.get("username")
            )}&email=${encodeURIComponent(e.target[0].value)}`
          );
        }
      })
      .catch(notifError);
  }

  const handleEmailOTP = async () => {
    try {
      await apiAnom.post('/auth/email-otp/send', {
        email: userEmail,
        first_name: formData.firstName,
        last_name: formData.lastName,
        username: formData.username,
      });
      navigate(`/validate-otp/user?username=${params.get("username")}&email=${userEmail}`, {
        state: {
          email: userEmail,
          username: formData.username,
          first_name: formData.firstName,
          last_name: formData.lastName
        }
      });
    } catch (error) {
      notifError(error);
    }
  };
  const charCardsSettings = {
    arrows: false,
    className: "center",
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
  };

  const charCards = charCardInfo.map((item, index) => (
    <SignInCarouselSlides
      key={index}
      backgroundColor={item.backgroundColor}
      iconUrl={
        window.innerWidth < 1024 ? item.iconUrlMobile : item.iconUrlDesktop
      }
    />
  ));

  return (
    <LayoutV2 padding="0">
      <div
        className="w-full sm:flex sm:justify-end relative"
        style={{
          padding:
            window.innerWidth < 1024 ? "27px 35.2px 0px 25px" : "32px 71px",
          minHeight: window.innerWidth < 1024 ? 661 : 514,
          height: window.innerHeight - (window.innerWidth < 1024 ? 210 : 145),
        }}
      >
        <div className="w-screen h-full absolute top-0 left-0">
          <Slider ref={charCardsRef} {...charCardsSettings}>
            {charCards.map((item) => item)}
          </Slider>
        </div>
        <div
          className="w-full h-full flex flex-col justify-start items-stretch relative z-10"
          style={{
            gap: "15px",
            width: window.innerWidth >= 1024 && "485px",
          }}
        >
          <p className="text-left text-white">
            <span
              className="font-bold text-2xl"
              style={{ lineHeight: "30.24px" }}
            >
              Selamat Datang
            </span>
            <br />
            <span
              className="font-medium text-base"
              style={{ lineHeight: "24px" }}
            >
              Raih masa depanmu bersama Exploration!
            </span>
          </p>
          <div
            className="w-full flex flex-col bg-white p-6 gap-5"
            style={{
              borderRadius: "12.33px",
            }}
          >
            <p
              className="text-left font-bold text-2xl text-black"
              style={{ lineHeight: "30.24px" }}
            >
              Masuk
            </p>
            <p
              className="text-left font-medium text-sm text-black"
              style={{ lineHeight: "17.78px" }}
            >
              Masukkan alamat email yang akan dihubungkan dengan username kamu
            </p>
            <form onSubmit={onSubmit} className="w-full flex flex-col gap-5">
              <FormField
                title="Email"
                placeholder="Masukkan alamat email"
                isRequired={true}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
              />
              <ButtonV2 type="submit" title="Masuk" />
            </form>
          </div>
        </div>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-100" onClose={() => {}}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-black/25"
                style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
              />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div
                className="flex min-h-full items-center justify-center text-center"
                style={{ paddingLeft: "25px", paddingRight: "35.2px" }}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel
                    className="w-full max-w-md transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all"
                    style={{ borderRadius: "12.33px" }}
                  >
                    <div className="w-full flex flex-col justify-start items-center gap-5">
                      <p
                        className="text-center font-bold text-2xl text-black"
                        style={{ lineHeight: "30.24px" }}
                      >
                        Email Sudah Terdaftar
                      </p>
                      <p
                        className="text-center font-medium text-sm text-black"
                        style={{ lineHeight: "17.78px" }}
                      >
                        <span>
                          Jika kamu ingin menggunakan email tersebut, seluruh
                          aktivitas yang ada pada akunmu akan hilang.
                        </span>
                        <br />
                        <br />
                        <span>Apakah kamu ingin reset akunmu?</span>
                      </p>
                      <div className="w-full flex flex-col justify-start items-stretch gap-3">
                        <ButtonV2
                          title="Reset Akun"
                          onClick={() => handleEmailOTP(
                            userEmail,
                            formData?.firstName,
                            formData?.lastName, 
                            formData?.username
                          )}
                        />
                        <ButtonV2
                          title="Cancel"
                          secondary={true}
                          onClick={() => {
                            setIsOpen(false);
                          }}
                        />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </LayoutV2>
  );
}
