import { MdArrowBack, MdArrowForward, MdClose } from "react-icons/md";
import Layout from "../../components/Layout";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useRef, useState } from "react";
import { api, apiAnom, notifError } from "../../utils/api";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/authState";
import HomeCardUnivTMB from "../../components/HomeCardUnivTMB";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import Slider from "react-slick";
import { Dialog, Transition } from "@headlessui/react";
import PageLoading from "../PageLoading";
import MajorsData from "../tmb_result_revamp/MajorsData.json";
import ButtonRekJurusanTMB from "../../components/ButtonRekJurusanTMB";
import LayoutV2 from "../../components/Layout_V2";
import HomeCardUnivV3 from "../../components/HomeCardUnivV3";
import ButtonV2 from "../../components/ButtonV2";
import { StarIcon } from "@heroicons/react/outline";
import FormField from "../../components/FormField";

export default function TMBResultJurusan({ screenWidth, screenHeight }) {
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const [majors, setMajors] = useState([]);
  const [selectedMajor, setSelectedMajor] = useState(0);
  const [otherMajors, setOtherMajors] = useState([
    "aktuaria",
    "akuntansi",
    "bisnis",
    "data-science",
    "hukum",
    "ilmu-komunikasi",
    "manajemen",
    "teknik-pangan",
    "teknik-biomedik",
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [universities, setUniversities] = useState();
  const sliderRef = useRef();
  const [currentSliderPage, setCurrentSliderPage] = useState(1);
  const majorDetailRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [dreams, setDreams] = useState([]);
  const [isDreamLoading, setIsDreamLoading] = useState(true);
  const [isAddDream, setIsAddDream] = useState(false);
  const [availableUniv, setAvailableUniv] = useState([]);
  const [availableMajor, setAvailableMajor] = useState([]);
  const [dreamData, setDreamData] = useState({mou_id: null, uni_id: null});

  const scrollToRef = () => {
    majorDetailRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!auth.isAuth) {
      navigate("/signin");
    }
    _api
      .get(`/tmb/result/mou/${window.location.pathname.split("/")[3]}`)
      .then((res) => {
        setIsLoading(false);
        setMajors(res.data.data.majors);
        setUniversities(res.data.data.univ_recommendations);
        setOtherMajors(res.data.data.other_majors);
      })
      .catch((error) => {
        window.Swal.fire({
          title: "Error!",
          text: error.response.data.error.message,
          icon: "error",
          confirmButtonText: "Tutup",
          allowOutsideClick: false,
        }).then(() => {
          navigate(-1);
        });
      });
  }, []);

  useEffect(() => {
    if (majors[selectedMajor]?.univ_recommendations.length > 3) {
      sliderRef.current.slickGoTo(0);
    }
  }, [selectedMajor]);

  const slickCarouselSetting = {
    dots: false,
    infinite: false,
    slidesToShow:
      majors[selectedMajor]?.univ_recommendations.length < 4
        ? majors[selectedMajor]?.univ_recommendations.length
        : 4,
    slidesToScroll: 1,
    draggable: false,
    swipe: false,
    autoplay: false,
    arrows: false,
    speed: 300,
    easing: "linear",
  };

  useEffect(() => {
    api
      .post("/university/major/search", {
        field_of_studies: [],
        limit: 999,
        page: 1,
        sort_direction: "DESC",
        tmbs: [""],
      })
      .then((res) => {
        setAvailableMajor(
          res.data.data.majors.map((item) => ({ id: item.id, name: item.name }))
        );
      })
      .catch(notifError);

    api
      .post("/university/search", {
        accreditation: [],
        limit: 999,
        major_ids: [],
        page: 1,
        province_ids: [],
        sort_direction: "DESC",
        type: [],
      })
      .then((res) => {
        setAvailableUniv(
          res.data.data.universities.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        );
      })
      .catch(notifError);
  }, []);

  useEffect(() => {
    api
      .get("/univ-dream/list")
      .then((res) => {
        setDreams(res.data.data);
        setIsDreamLoading(false);
      })
      .catch(notifError);
  }, [isDreamLoading]);

  function onDreamSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    api
      .post(`/univ-dream/add`, dreamData)
      .then(() => {
        window.Swal.fire({
          title: "Success!",
          text: "Kuliah impian kamu telah terdaftar!",
          icon: "success",
          confirmButtonText: "Tutup",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setIsAddDream(false);
            setIsDreamLoading(true);
          }
        });
      })
      .catch(() => {
        window.Swal.fire({
          title: "Failed!",
          text: "Kuliah impian dengan pilihan yang sama sudah tersimpan!",
          icon: "error",
          confirmButtonText: "Tutup",
        });
      });
  }

  if (isLoading || majors.length === 0) {
    return <PageLoading />;
  }

  return (
    <LayoutV2 padding="0px">
      <button
        onClick={() => {
          navigate(
            `/tmb-result-detail/karakter/${
              window.location.pathname.split("/")[3]
            }`
          );
        }}
        className="fixed w-12 sm:w-44 h-12 rounded-lg bg-white flex justify-center items-center sm:gap-2 z-50"
        style={{
          top: screenWidth >= 1024 ? "128px" : "95px",
          right:
            screenWidth >= 1920
              ? "120px"
              : screenWidth >= 1024
              ? "55px"
              : "30px",
          scale: screenWidth >= 1920 && "125%",
          boxShadow: "0px 0px 20px 0px rgba(64, 69, 79, 0.4)",
        }}
      >
        <img
          src="/icon_theatre_primary.svg"
          className="relative z-10"
          style={{
            width: screenWidth >= 1024 ? "19px" : "24px",
            height: screenWidth >= 1024 ? "19px" : "24px",
          }}
        />
        {screenWidth >= 1024 && (
          <p
            className="text-left font-semibold text-sm text-primaryColor"
            style={{ lineHeight: "21px" }}
          >
            Lihat karaktermu
          </p>
        )}
      </button>
      <div className="relative w-full">
        <img
          src={
            screenWidth < 1024
              ? "/bg_tmb_result_jurusan.png"
              : "/bg_tmb_result_jurusan_desktop.png"
          }
          className="w-full object-cover"
          style={{ height: screenWidth >= 1920 ? "736px" : "693px" }}
        />
        <button
          onClick={() => {
            navigate(
              `/tes-minat-result/${window.location.pathname.split("/")[3]}`
            );
          }}
          className="absolute z-10"
          style={{
            left:
              screenWidth >= 1920
                ? "120px"
                : screenWidth >= 1024
                ? "74.78px"
                : "34px",
            top:
              screenWidth >= 1920
                ? "60px"
                : screenWidth >= 1024
                ? "46.33px"
                : "52px",
          }}
        >
          <MdArrowBack
            className="h-auto text-white"
            style={{
              width: screenWidth >= 1024 ? "26px" : "20px",
              scale: screenWidth >= 1920 && "150%",
            }}
          />
        </button>
        <div
          className="w-full absolute top-0 flex flex-col justify-start items-stretch sm:items-center px-12"
          style={{ paddingTop: screenWidth >= 1920 ? "140px" : "69px" }}
        >
          <p
            className="text-center font-bold text-xs sm:text-sm text-mustard3 uppercase"
            style={{
              letterSpacing: screenWidth >= 1024 ? "1.33px" : "2px",
              lineHeight: screenWidth >= 1024 ? "21px" : "18px",
              scale: screenWidth >= 1920 && "125%",
            }}
          >
            Rekomendasi Jurusan Kamu
          </p>
          <p
            className="text-center font-bold text-white"
            style={{
              marginTop: screenWidth >= 1024 ? "14px" : "22px",
              maxWidth: "417px",
              letterSpacing: screenWidth >= 1024 ? "-0.8px" : "-1.2px",
              lineHeight: screenWidth >= 1024 ? "43.2px" : "28.8px",
              fontSize: screenWidth >= 1024 ? "36px" : "24px",
            }}
          >
            <span className="text-mustard3">5 </span>Rekomendasi jurusan
            berdasarkan karakter kamu adalah:
          </p>
          <div
            className="w-full flex flex-col sm:flex-row justify-start sm:justify-center items-stretch"
            style={{
              gap:
                screenWidth >= 1920
                  ? "70px"
                  : screenWidth >= 1024
                  ? "16.97px"
                  : "10px",
              marginTop: screenWidth >= 1024 ? "51px" : "16px",
            }}
          >
            {majors.map((item, index) => (
              <ButtonRekJurusanTMB
                key={index}
                item={item}
                onClick={() => {
                  setSelectedMajor(index);
                  scrollToRef();
                }}
                majorsData={MajorsData}
                screenWidth={screenWidth}
              />
            ))}
          </div>
          <div
            className="w-full px-3 sm:px-0 flex flex-col justify-start items-center sm:flex-row sm:justify-center"
            style={{
              marginTop:
                screenWidth >= 1920
                  ? "100px"
                  : screenWidth >= 1024
                  ? "81.91px"
                  : "31px",
              maxWidth: "472px",
              gap: "10px",
            }}
          >
            {screenWidth < 1024 && (
              <button
                onClick={() => {
                  if (dreams.length === 2) {
                    window.Swal.fire({
                      text: "Kuliah impian sudah penuh, harap hapus dulu salah satu di profilmu",
                      icon: "error",
                      confirmButtonText: "Tutup",
                    });
                  } else {
                    setIsAddDream(true);
                  }
                }}
                className="w-full h-full flex justify-start items-center"
                style={{
                  padding: "15px 26px",
                  borderRadius: "6.67px",
                  backgroundColor: "rgba(255, 202, 45, 1)",
                  gap: "30px",
                }}
              >
                <StarIcon className="h-5 w-5" />
                <p
                  className="text-left font-bold text-sm text-black"
                  style={{ lineHeight: "17.64px" }}
                >
                  Kuliah impian kamu
                </p>
              </button>
            )}
            <button
              onClick={() => {
                setIsModalOpen(true);
              }}
              className="w-full h-full flex justify-center items-center gap-3 bg-white"
              style={{ padding: "15px 26px", borderRadius: "6.67px" }}
            >
              <img
                src="/icon_toga_primary.svg"
                className="object-contain w-5 h-4"
              />
              <p
                className="text-left font-bold text-sm text-primaryColor whitespace-nowrap"
                style={{ lineHeight: "17.64px" }}
              >
                {screenWidth >= 1024
                  ? "Jurusan lainnya untukmu"
                  : "Jurusan lain cocok untukmu"}
              </p>
            </button>
            {screenWidth >= 1024 && (
              <button
                onClick={() => {
                  if (dreams.length === 2) {
                    window.Swal.fire({
                      text: "Kuliah impian sudah penuh, harap hapus dulu salah satu di profilmu",
                      icon: "error",
                      confirmButtonText: "Tutup",
                    });
                  } else {
                    setIsAddDream(true);
                  }
                }}
                className="w-max h-full flex justify-center items-center gap-3"
                style={{
                  padding: "15px 26px",
                  borderRadius: "6.67px",
                  backgroundColor: "rgba(255, 202, 45, 1)",
                }}
              >
                <StarIcon className="h-5 w-5" />
                <p
                  className="text-left font-bold text-sm text-black whitespace-nowrap"
                  style={{ lineHeight: "17.64px" }}
                >
                  Kuliah impian kamu
                </p>
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        ref={majorDetailRef}
        className="relative w-full"
        style={{ minHeight: screenWidth < 1024 && "706px" }}
      >
        <img
          src={
            screenWidth < 1024
              ? "/illu_tmb_result_circle.svg"
              : "/illu_tmb_result_blob_desktop.svg"
          }
          className="absolute z-10 tablet:bottom-0"
          style={{
            top: screenWidth < 1024 && "125px",
            right: screenWidth < 1024 ? "50%" : "202px",
            opacity: "100%",
            transform: screenWidth < 1024 && "translate(50%, 0)",
          }}
        />
        <div
          className="w-full h-full flex flex-1 flex-col justify-start items-start px-4 sm:px-19"
          style={{
            paddingTop: screenWidth >= 1024 ? "55px" : "39px",
            paddingBottom: "30px",
            backgroundColor: "#F4F4F4",
          }}
        >
          <div className="w-full flex justify-center sm:justify-start relative z-20">
            <p
              className="text-center sm:text-left font-bold text-2xl sm:text-40px text-black"
              style={{
                width: screenWidth < 1024 && "272px",
                lineHeight: screenWidth >= 1024 ? "48px" : "28.8px",
              }}
            >
              Kenalin, tokoh terkenal {screenWidth >= 1024 && <br />} sesuai
              jurusanmu!
            </p>
          </div>
          <div
            className="w-full h-full flex flex-col sm:flex-row sm:justify-between sm:pt-5 relative z-20"
            style={{
              marginTop: "23px",
              // gap: screenWidth >= 1024 && "212px",
            }}
          >
            <div
              className="flex flex-col justify-start items-center sm:items-stretch"
              style={{ width: screenWidth >= 1024 && "656px" }}
            >
              <div className="w-full flex flex-col justify-start items-center">
                <div className="w-full flex flex-row justify-between items-center">
                  {screenWidth < 1024 && (
                    <button
                      onClick={() => {
                        setCurrentSliderPage(1);
                        setSelectedMajor(selectedMajor - 1);
                      }}
                      disabled={selectedMajor === 0}
                    >
                      <MdArrowBack
                        className="text-primaryColor h-5 w-auto"
                        style={{
                          opacity: selectedMajor === 0 ? "35%" : "100%",
                        }}
                      />
                    </button>
                  )}
                  <p
                    className="text-center sm:text-left font-bold text-2xl sm:text-3xl text-primaryColor"
                    style={{
                      lineHeight: screenWidth >= 1024 ? "38.4px" : "28.8px",
                    }}
                  >
                    {majors[selectedMajor].name}
                  </p>
                  {screenWidth < 1024 && (
                    <button
                      onClick={() => {
                        setCurrentSliderPage(1);
                        setSelectedMajor(selectedMajor + 1);
                      }}
                      disabled={selectedMajor === majors.length - 1}
                    >
                      <MdArrowForward
                        className="text-primaryColor h-5 w-auto"
                        style={{
                          opacity:
                            selectedMajor === majors.length - 1
                              ? "35%"
                              : "100%",
                        }}
                      />
                    </button>
                  )}
                </div>
              </div>
              {screenWidth < 1024 && (
                <div
                  className="flex flex-row gap-1 justify-center items-center"
                  style={{ marginTop: "14px" }}
                >
                  {majors.map((_, index) => (
                    <div
                      key={index}
                      className="rounded-full"
                      style={{
                        width: "6px",
                        height: "6px",
                        backgroundColor: "rgba(169, 169, 169, 1)",
                        opacity: selectedMajor === index ? "100%" : "35%",
                      }}
                    />
                  ))}
                </div>
              )}
              <div
                className="w-full px-3 sm:px-0 mt-6"
                style={{
                  minWidth: screenWidth < 1024 ? "337px" : "656px",
                  maxWidth: screenWidth < 1024 ? "337px" : "656px",
                }}
              >
                <p
                  className="w-full text-justify tablet:text-justify font-normal text-black"
                  style={{
                    maxHeight: screenWidth >= 1024 ? "216px" : "150px",
                    fontSize: screenWidth >= 1024 ? "17.78px" : "16px",
                    lineHeight: screenWidth >= 1024 ? "26.67px" : "24.8px",
                    letterSpacing: screenWidth >= 1024 && "-0.64px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      screenWidth < 1024
                        ? MajorsData[
                            MajorsData.findIndex(
                              (icon) =>
                                icon.major_id === majors[selectedMajor].id
                            )
                          ]?.desc_mobile.substring(0, 520)
                        : MajorsData[
                            MajorsData.findIndex(
                              (icon) =>
                                icon.major_id === majors[selectedMajor].id
                            )
                          ]?.desc_desktop.substring(0, 520),
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col justify-start items-center">
              <div
                className="rounded-full border-primaryColor"
                style={{
                  marginTop: screenWidth < 1024 && "45px",
                  width: screenWidth >= 1024 ? "267.79px" : "216px",
                  height: screenWidth >= 1024 ? "267.79px" : "216px",
                  backgroundColor: "rgba(196, 196, 196, 1)",
                  borderWidth: screenWidth >= 1024 ? "6.54px" : "5px",
                }}
              >
                <img
                  src={majors[selectedMajor]?.picture_url}
                  className="h-full w-full object-cover rounded-full"
                />
              </div>
              <p
                className="text-center font-semibold"
                style={{
                  marginTop: "35px",
                  color: "rgba(0, 0, 0, 0.5)",
                  fontSize: screenWidth >= 1024 ? "21.33px" : "16px",
                  lineHeight: screenWidth >= 1024 ? "25.6px" : "19.2px",
                  letterSpacing: screenWidth >= 1024 ? "-0.72px" : "-0.2px",
                }}
              >
                {majors[selectedMajor]?.alumni_name}
                <br />
                {`(${majors[selectedMajor]?.profession})`}
              </p>
            </div>
          </div>
          {screenWidth >= 1024 && (
            <div className="w-full flex flex-row justify-center items-center gap-2 mt-7 relative z-20">
              <button
                onClick={() => {
                  setCurrentSliderPage(1);
                  setSelectedMajor(selectedMajor - 1);
                }}
                disabled={selectedMajor === 0}
                className="bg-white"
                style={{
                  borderRadius: "7.11px",
                  padding: "10.67px",
                  width: "42.67px",
                  height: "42.67px",
                  opacity: selectedMajor === 0 && "40%",
                }}
              >
                <ArrowLeftIcon
                  className="object-contain"
                  style={{
                    color: "rgba(169, 169, 169)",
                  }}
                />
              </button>
              <button
                onClick={() => {
                  setCurrentSliderPage(1);
                  setSelectedMajor(selectedMajor + 1);
                }}
                disabled={selectedMajor === majors.length - 1}
                className="bg-white"
                style={{
                  borderRadius: "7.11px",
                  padding: "10.67px",
                  width: "42.67px",
                  height: "42.67px",
                  opacity: selectedMajor === majors.length - 1 && "40%",
                }}
              >
                <ArrowRightIcon
                  className="object-contain"
                  style={{
                    color: "rgba(169, 169, 169)",
                  }}
                />
              </button>
            </div>
          )}
        </div>
      </div>
      {majors[selectedMajor].univ_recommendations?.length > 0 && (
        <div
          className="relative w-full"
          style={{
            height: screenWidth >= 1024 && "531px",
          }}
        >
          <img
            src={
              screenWidth >= 1024
                ? "/bg_tmb_result_unirecc_desktop.svg"
                : "/bg_tmb_result_unirecc.svg"
            }
            className="w-full h-full object-cover"
          />
          <div
            className="w-full flex flex-col absolute top-0 sm:px-13 fhd:px-60"
            style={{ paddingTop: screenWidth >= 1024 ? "49.11px" : "30px" }}
          >
            <div className="sm:flex sm:flex-row sm:items-center sm:justify-between">
              <p
                className="text-left font-bold text-white"
                style={{
                  marginLeft: screenWidth < 1024 && "17.34px",
                  fontSize: screenWidth >= 1024 ? "42.67px" : "24px",
                  lineHeight: screenWidth >= 1024 ? "46.08px" : "25.92px",
                  letterSpacing: screenWidth >= 1024 ? "-0.71px" : "-0.87px",
                }}
              >
                Rekomendasi Kampus
              </p>
            </div>
            {screenWidth >= 1024 ? (
              <Slider
                ref={sliderRef}
                {...slickCarouselSetting}
                id="slick"
                className="mt-8"
              >
                {majors[selectedMajor].univ_recommendations?.map(
                  (item, index) => {
                    return (
                      <div key={index}>
                        <HomeCardUnivV3
                          id={item.id}
                          univData={item}
                          fav={item.favorite}
                          width={
                            screenWidth < 1440
                              ? "272.54px"
                              : screenWidth < 1910
                              ? "310px"
                              : "430px"
                          }
                          height={
                            screenWidth < 1440
                              ? "281.13px"
                              : screenWidth < 1910
                              ? "317px"
                              : "437px"
                          }
                          screenWidth={screenWidth}
                        />
                        {/* <HomeCardUnivTMB
                          id={item.id}
                          univData={item}
                          fav={item.favorite}
                          screenWidth={screenWidth}
                        /> */}
                      </div>
                    );
                  }
                )}
              </Slider>
            ) : (
              <div
                className="flex flex-row gap-5 justify-start overflow-x-scroll"
                style={{
                  marginTop: "25.31px",
                  marginLeft: "17.34px",
                }}
              >
                {majors[selectedMajor].univ_recommendations?.map(
                  (item, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          marginRight:
                            index ===
                              majors[selectedMajor].univ_recommendations
                                .length -
                                1 && "17.34px",
                        }}
                      >
                        <HomeCardUnivTMB
                          id={item.id}
                          univData={item}
                          fav={item.favorite}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            )}
            <div
              className="w-full sm:flex sm:justify-between"
              style={{
                marginTop: "43.34px",
                paddingLeft: "17.34px",
                paddingRight: "17.34px",
              }}
            >
              <div className="flex flex-row items-center justify-start w-full"></div>
              {screenWidth >= 1024 &&
                majors[selectedMajor].univ_recommendations.length > 3 && (
                  <div className="flex flex-row gap-2 w-1/6 items-center justify-center">
                    {/* <div className="flex flex-row items-center justify-center text-base mac:text-xl text-right text-white font-normal tracking-wide mr-10">
                      <p>{currentSliderPage}</p>
                      <p>{`/${Math.ceil(
                        majors[selectedMajor].univ_recommendations.length / 3
                      )}`}</p>
                    </div> */}
                    <button
                      disabled={currentSliderPage === 1}
                      onClick={() => {
                        sliderRef.current.slickPrev();
                        setCurrentSliderPage(currentSliderPage - 1);
                      }}
                      className={`bg-white rounded-lg p-3 ${
                        currentSliderPage > 1 ? "opacity-100" : "opacity-30"
                      }`}
                    >
                      <ArrowLeftIcon
                        className="w-4 h-4 mac:w-5 mac:h-5"
                        color="rgba(23, 23, 23, 0.4)"
                      />
                    </button>
                    <button
                      disabled={
                        currentSliderPage ===
                        Math.ceil(
                          majors[selectedMajor].univ_recommendations.length / 3
                        )
                      }
                      onClick={() => {
                        sliderRef.current.slickNext();
                        setCurrentSliderPage(currentSliderPage + 1);
                      }}
                      className={`bg-white rounded-lg p-3 ${
                        currentSliderPage ===
                        Math.ceil(
                          majors[selectedMajor].univ_recommendations.length / 3
                        )
                          ? "opacity-30"
                          : "opacity-100"
                      }`}
                    >
                      <ArrowRightIcon
                        className="w-4 h-4 mac:w-5 mac:h-5"
                        color="rgba(23, 23, 23, 0.4)"
                      />
                    </button>
                  </div>
                )}
              <div className="flex flex-row items-center justify-end w-full">
                <button
                  onClick={() => {
                    navigate("/explore-kampus");
                  }}
                  className="w-full h-full sm:w-auto sm:h-auto flex flex-row items-center justify-center bg-white"
                  style={{
                    height: screenWidth >= 1024 ? "46.22px" : "56.35px",
                    borderRadius: "8.67px",
                    gap: screenWidth >= 1024 ? "7.11px" : "8.67px",
                    paddingLeft: screenWidth >= 1024 && "21.33px",
                    paddingRight: screenWidth >= 1024 && "17.78px",
                  }}
                >
                  <div className="text-right font-semibold text-base text-primaryColor">
                    Lihat kampus lainnya
                  </div>
                  <ArrowRightIcon className="w-5 h-5 text-primaryColor" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="relative w-full">
        <img
          src={
            screenWidth >= 1024
              ? "/bg_tmb_result_footer_desktop.svg"
              : "/bg_tmb_result_footer.svg"
          }
          className="w-full object-cover"
          style={{
            height: screenWidth >= 1920 && "500px",
          }}
        />
        <div
          className="w-full flex flex-col justify-start items-center absolute top-0"
          style={{ paddingTop: screenWidth >= 1024 ? "115px" : "76px" }}
        >
          <p
            className="text-center font-bold text-2xl sm:text-40px text-white"
            style={{
              width: screenWidth >= 1024 ? "460px" : "355.33px",
              lineHeight: screenWidth >= 1024 ? "48px" : "28.8px",
              letterSpacing: "-1.3px",
            }}
          >
            Masih penasaran?
            <br />
            Explore Jurusan lainnya!
          </p>
          <div
            className="flex flex-col sm:flex-row justify-start items-center gap-3 sm:gap-9"
            style={{ marginTop: screenWidth >= 1024 ? "58.33px" : "52px" }}
          >
            <button
              onClick={() => {
                navigate("/explore-jurusan");
              }}
              className="bg-white flex items-center justify-center"
              style={{
                width: screenWidth >= 1024 ? "166px" : "250px",
                height: screenWidth >= 1024 ? "36.44px" : "50px",
                borderRadius: screenWidth >= 1024 ? "5.33px" : "8.67px",
              }}
            >
              <p
                className="text-center font-semibold text-primaryColor"
                style={{
                  fontSize: screenWidth >= 1024 ? "14.22px" : "16px",
                  lineHeight: screenWidth >= 1024 ? "21.33px" : "24px",
                }}
              >
                Explore Jurusan
              </p>
            </button>
            <button
              onClick={() => {
                navigate("/tes-minat");
              }}
              className="bg-primaryColor flex items-center justify-center"
              style={{
                width: screenWidth >= 1024 ? "166px" : "250px",
                height: screenWidth >= 1024 ? "36.44px" : "50px",
                borderRadius: screenWidth >= 1024 ? "5.33px" : "8.67px",
              }}
            >
              <p
                className="text-center font-semibold text-white"
                style={{
                  fontSize: screenWidth >= 1024 ? "14.22px" : "16px",
                  lineHeight: screenWidth >= 1024 ? "21.33px" : "24px",
                }}
              >
                Tes Minat
              </p>
            </button>
          </div>
        </div>
        <img
          src={
            screenWidth >= 1024
              ? "/illu_tmb_result_sierra_footer_desktop.png"
              : "/illu_tmb_result_sierra_footer.png"
          }
          className="absolute bottom-0 sm:right-0"
          style={{ left: screenWidth < 1024 && "2.17px" }}
        />
      </div>

      {/* other jurusan modal */}
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-100" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
            />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex flex-col w-full h-full items-center justify-start py-10">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel>
                  <div
                    className="flex flex-col justify-start items-stretch relative"
                    style={{
                      width: screenWidth >= 1024 ? "944px" : "329px",
                    }}
                  >
                    <button
                      className="absolute top-0 left-0 z-10"
                      style={{
                        top: "16.83px",
                        left: "19px",
                      }}
                      onClick={() => {
                        setIsModalOpen(false);
                      }}
                    >
                      <MdClose
                        className="text-white"
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </button>
                    <div
                      className="absolute w-full top-0 flex justify-center items-center"
                      style={{ height: screenWidth < 1024 ? "110px" : "145px" }}
                    >
                      <p
                        className="text-center font-bold text-base sm:text-2xl text-white"
                        style={{
                          lineHeight: screenWidth >= 1024 ? "28.8px" : "19.2px",
                          maxWidth: screenWidth < 1024 && "260px",
                        }}
                      >
                        Jurusan-jurusan lainnya yang cocok{" "}
                        {screenWidth >= 1024 && <br />}
                        untukmu !
                      </p>
                    </div>
                    <img
                      src="/bg_tmb_jurusan_modal.svg"
                      className="w-full object-cover rounded-t-xl"
                      style={{
                        maxHeight: screenWidth < 1024 ? "110px" : "145px",
                      }}
                    />
                    <div
                      className="w-full flex flex-1 flex-col justify-start items-center bg-white rounded-b-xl"
                      style={{
                        paddingBottom: screenWidth >= 1024 ? "24px" : "22px",
                      }}
                    >
                      <div className="w-full flex justify-center relative">
                        <img
                          src="/vec_purple_triangle.svg"
                          className="absolute"
                          style={{ top: "-1px" }}
                        />
                      </div>
                      <div
                        className="w-max h-full flex flex-col justify-start items-center overflow-y-auto"
                        style={{
                          marginTop: screenWidth >= 1024 ? "37px" : "34px",
                          gap: screenWidth >= 1024 ? "16px" : "10.4px",
                        }}
                      >
                        {otherMajors.map((item, index) => (
                          <button
                            onClick={() => {
                              navigate(`/explore-jurusan/${item.slug}`);
                            }}
                            key={index}
                            className="relative flex items-center"
                            style={{
                              minHeight:
                                screenWidth >= 1024 ? "81px" : "53.57px",
                              maxHeight:
                                screenWidth >= 1024 ? "81px" : "53.57px",
                              width: screenWidth >= 1024 ? "854px" : "291.26px",
                              borderRadius:
                                screenWidth >= 1024 ? "10.23px" : "4px",
                              backgroundImage: `url("/bg_tmb_result_pilihan_jurusan_white.svg")`,
                              // backgroundImage: `url("/bg_tmb_result_pilihan_jurusan.svg")`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }}
                          >
                            <div
                              className="flex flex-row gap-5 w-full h-full items-center justify-start absolute left-0 sm:pl-4"
                              style={{
                                paddingLeft: screenWidth < 1024 && "8.37px",
                              }}
                            >
                              <div
                                className="bg-white"
                                style={{
                                  borderRadius:
                                    screenWidth >= 1024 ? "11.98px" : "5px",
                                  width:
                                    screenWidth >= 1024 ? "65.85px" : "44px",
                                  height:
                                    screenWidth >= 1024 ? "65.85px" : "44px",
                                }}
                              >
                                <img
                                  src={
                                    MajorsData[
                                      MajorsData.findIndex(
                                        (icon) => icon.major_id === item.id
                                      )
                                    ]?.icon_url
                                  }
                                  className="w-full h-full object-cover"
                                />
                              </div>
                              <p
                                className="text-left font-bold text-sm sm:text-base text-primaryColor sm:flex sm:items-center capitalize line-clamp-2 overflow-ellipsis"
                                style={{
                                  lineHeight:
                                    screenWidth >= 1024 ? "19.2px" : "16.8px",
                                  width: screenWidth < 1024 && "139px",
                                  height: screenWidth >= 1024 && "38px",
                                }}
                              >
                                {item.name}
                              </p>
                            </div>
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Edit Dream */}
      <Transition appear show={isAddDream} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-100"
          onClose={() => {
            setIsAddDream(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black/25"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
            />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div
              className="flex min-h-full items-start justify-center text-center"
              style={{ padding: "86px 30px" }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="w-full flex flex-col justify-center items-stretch transform overflow-hidden bg-white p-6 sm:p-10 text-left align-middle shadow-xl transition-all"
                  style={{
                    borderRadius: "10px",
                    gap: "22px",
                    maxWidth: "485px",
                  }}
                >
                  <p
                    className="text-center font-bold text-2xl text-black"
                    style={{ lineHeight: "30.24px" }}
                  >
                    Kuliah Impian
                  </p>
                  <form
                    onSubmit={onDreamSubmit}
                    className="w-full flex flex-col justify-start items-stretch"
                    style={{ gap: "22px" }}
                  >
                    <FormField
                      fieldType="autocomplete"
                      title="Jurusan impian"
                      placeholder="Masukkan jurusan impianmu"
                      isRequired={true}
                      ddOptions={availableMajor}
                      onInput={(id) => {
                        setDreamData(prev => ({
                          ...prev,
                          mou_id: parseInt(id)
                        }));
                      }}
                    />
                    <FormField
                      fieldType="autocomplete"
                      title="Kampus impian"
                      placeholder="Masukkan kampus impianmu"
                      isRequired={true}
                      ddOptions={availableUniv}
                      onInput={(id) => {
                        setDreamData(prev => ({
                          ...prev,
                          uni_id: id
                        }));
                      }}
                    />
                    <div className="w-full flex flex-col justify-start items-stretch gap-3">
                      <ButtonV2 type="submit" title="Simpan" />
                      <ButtonV2
                        type="button"
                        title="Cancel"
                        secondary={true}
                        onClick={() => {
                          setIsAddDream(false);
                        }}
                      />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </LayoutV2>
  );
}
