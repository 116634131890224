import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import FormFieldText from "./FormFieldText";
import FormFieldDropdown from "./FormFieldDropdown";
import FormFieldAutocomplete from './FormFieldAutocomplete'

export default function FormField({
  id,
  fieldType,
  title,
  placeholder,
  isRequired,
  isPassword,
  value,
  onChange,
  ddOptions,
  isDisabled,
  onInput,
  error,
}) {
  const FormFieldType = (type) => {
    switch (type) {
      case "dropdown":
        return (
          <FormFieldDropdown
            id={id}
            onInput={onInput}
            ddOptions={ddOptions}
            title={title}
            placeholder={placeholder}
            value={value}
            isRequired={isRequired}
          />
        );
        case "autocomplete":
          return (
            <FormFieldAutocomplete
              id={id}
              title={title}
              placeholder={placeholder}
              value={value}
              options={ddOptions}
              isRequired={isRequired}
              onSelect={onInput}
            />
          );
      default:
        return (
          <div className="flex flex-col">
            <FormFieldText
              id={id}
              onInput={onInput}
              title={title}
              placeholder={placeholder}
              value={value}
              isPassword={isPassword}
              isRequired={isRequired}
              onChange={onChange}
              isDisabled={isDisabled}
            />
            {error && (
                <span className="text-red-500 text-sm mt-1">{error}</span>
            )}
          </div>
        );
    }
  };

  return FormFieldType(fieldType);
}
