import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import CardClassRoom from "../components/CardClassRoomV3";
import Layout from "../components/Layout";
import Button from "../components/base/Button";
import useSWR from "swr";
import { api, notifError } from "../utils/api";
import { useRecoilValue } from "recoil";
import { authState } from "../atoms/authState";
import Avatar from "react-avatar";
import CardUniversity from "../components/CardUniversity";
import CardMajor from "../components/CardMajor";
import TMBResultCard from "../components/TMBResultCard";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import RegisProgressCard from "../components/RegisProgressCard";
import PageLoading from "./PageLoading";
import TMBResultCardV2 from "../components/TMBResultCardV2";
import MiniQuizResultCard from "../components/MiniQuizResultCard";
import LayoutV2 from "../components/Layout_V2";
import ButtonV2 from "../components/ButtonV2";
import ProfileDream from "../components/ProfileDream";
import { Fragment } from "react";
import FormField from "../components/FormField";
import { PlusSmIcon } from "@heroicons/react/solid";
import Cookies from "js-cookie";

export default function ProfileV2() {
  const params = new URLSearchParams(window.location.search);
  const [tab, setTab] = useState("tmb");
  const [tabFavorite, setTabFavorite] = useState("university");
  const auth = useRecoilValue(authState);
  const navigate = useNavigate();
  const { data: upcoming } = useSWR("/students/upcoming", api);
  const { data: past } = useSWR("/students/past", api);
  const { data: university } = useSWR("/favorites/university", api);
  const { data: major } = useSWR("/favorites/major", api);
  const { data: tmbResults } = useSWR("/tmb/test-result-list", api);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [admissionFormList, setAdmissionFormList] = useState([]);
  const [miniQuizData, setMiniQuizData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isDreamLoading, setIsDreamLoading] = useState(true);
  const [dreams, setDreams] = useState([]);
  const [isEditDream, setIsEditDream] = useState(false);
  const [editedDreamData, setEditedDreamData] = useState();
  const [availableUniv, setAvailableUniv] = useState([]);
  const [availableMajor, setAvailableMajor] = useState([]);
  const [isJustFinishTMB, setIsJustFinishTMB] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    if (Cookies.get("justFinishTMB")) {
      setIsJustFinishTMB(true);
    }
  }, []);

  const tabs = [
    { id: 1, name: "Tes Minat Bakat", code: "tmb", unavailable: false },
    { id: 2, name: "Mini Quiz", code: "quiz", unavailable: false },
    { id: 3, name: "Kelas Mendatang", code: "upcoming", unavailable: false },
    { id: 4, name: "Kelas Sebelumnya", code: "past", unavailable: false },
  ];

  const regisProgressTabs = [
    { id: 1, name: "Draf", code: "DRAFT" },
    { id: 2, name: "Menunggu Pembayaran", code: "SUBMITTED" },
    { id: 3, name: "Berhasil", code: "PAID" },
    { id: 4, name: "Gagal", code: "VA EXPIRED" },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [selectedRegisProgressTab, setSelectedRegisProgressTab] = useState(
    regisProgressTabs[0]
  );
  const [isActivityDDOpen, setIsActivityDDOpen] = useState(false);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    if (auth.fname.length < 1 || auth.verified_at.split("-")[0] === "0001") {
      navigate("/profile-edit");
    }

    api
      .get("/students/univ-admission/list")
      .then((res) => {
        if (!res.data) {
          setAdmissionFormList([]);
        }
        setAdmissionFormList(res.data.data);
      })
      .catch((_) => {
        setAdmissionFormList([]);
      });

    api
      .get("/ptmq/student/profile")
      .then((res) => {
        setMiniQuizData(res.data.data);
      })
      .catch(notifError);

    api
      .post("/university/major/search", {
        field_of_studies: [],
        limit: 999,
        page: 1,
        sort_direction: "DESC",
        tmbs: [""],
      })
      .then((res) => {
        setAvailableMajor(
          res.data.data.majors.map((item) => ({ id: item.id, name: item.name }))
        );
      })
      .catch(notifError);

    api
      .post("/university/search", {
        accreditation: [],
        limit: 999,
        major_ids: [],
        page: 1,
        province_ids: [],
        sort_direction: "DESC",
        type: [],
      })
      .then((res) => {
        setAvailableUniv(
          res.data.data.universities.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        );
      })
      .catch(notifError);

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    api
      .get("/univ-dream/list")
      .then((res) => {
        setDreams(res.data.data);
        setIsDreamLoading(false);
      })
      .catch(notifError);
  }, [isDreamLoading]);

  useEffect(() => {
    if (availableMajor.length > 0 && availableUniv.length > 0) {
      setIsLoading(false);
    }
  }, [availableMajor, availableUniv]);

  useEffect(() => {
    if (!isEditDream) {
      setEditedDreamData();
    }
  }, [isEditDream]);

  var screenWidth = windowSize.innerWidth;

  const fullName = `${auth.fname} ${auth.lname}`;

  function onDreamSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      mou_id: editedDreamData.mou_id,
      uni_id: editedDreamData.uni_id,
      ...(editedDreamData?.id && { id: editedDreamData.id })
    };

    api
    .post(`/univ-dream/${editedDreamData?.id ? 'update' : 'add'}`, payload)
      .then(() => {
        window.Swal.fire({
          title: "Success!",
          text: "Kuliah impian kamu telah terdaftar!",
          icon: "success",
          confirmButtonText: "Tutup",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setIsEditDream(false);
            setIsDreamLoading(true);
          }
        });
      })
      .catch(() => {
        window.Swal.fire({
          title: "Failed!",
          text: "Kuliah impian dengan pilihan yang sama sudah tersimpan!",
          icon: "error",
          confirmButtonText: "Tutup",
        });
      });
  }

  function deleteDream(id) {
    api
      .post(`/univ-dream/delete`, { id: id })
      .then(() => {
        window.Swal.fire({
          title: "Success!",
          text: "Impian telah dihapus",
          icon: "success",
          confirmButtonText: "Tutup",
          allowOutsideClick: false,
        }).then(() => {
          setIsDreamLoading(false);
        });
      })
      .catch(notifError);
  }

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <LayoutV2 padding="0">
      <div
        className="px-8 pb-12 tablet:pt-8 tablet:pb-20 bg-white"
        style={{
          paddingLeft: screenWidth >= 1024 && "71.11px",
          paddingRight: screenWidth >= 1024 && "71.11px",
        }}
      >
        <div
          className="text-center tablet:text-left rounded-xl bg-white sm:grid sm:grid-cols-2"
          style={{
            boxShadow: "4px 4px 40px 0px rgba(64, 69, 79, 0.16)",
          }}
        >
          <div
            className="tablet:flex items-center"
            style={{
              padding: screenWidth < 1024 ? "18px 0px" : "30px 48px",
              gap: screenWidth < 1024 ? "32px" : "42px",
            }}
          >
            <Avatar
              name={fullName}
              color="#ffad38"
              size={120}
              className="block h-32 mx-auto tablet:mx-0 rounded-full shadow-lg"
            />
            <div className="flex flex-col mt-10 sm:mt-0">
              <div
                className="text-lg font-semibold sm:text-2xl text-black"
                style={{ lineHeight: screenWidth >= 1024 && "36px" }}
              >
                {fullName}
              </div>
              <div>
                <div className="tablet:text-lg text-gray-700 line-clamp-1">
                  {auth.username ? auth.username : "-"}
                </div>
                <div className="tablet:text-lg text-gray-700 line-clamp-1">
                  {auth.email}
                </div>
                {auth.phone.length > 6 && (
                  <div className="tablet:text-lg text-gray-700">
                    {"+"}
                    {auth.phone.replace("-", "")}
                  </div>
                )}
                {auth.school_name !== "Others" ? (
                  <div className="tablet:text-lg text-gray-700">
                    {auth.school_name}
                  </div>
                ) : (
                  <div className="tablet:text-lg text-gray-700">
                    {auth.custom_school_name}
                  </div>
                )}
                <p className="tablet:text-lg text-gray-700">
                  {`${auth.education_level_name} ${
                    auth.major !== "Not Applicable" ? auth.major : ""
                  } ${auth.class}`}
                </p>
              </div>
              <div className="w-full flex justify-center sm:justify-start mt-10 sm:mt-3">
                <ButtonV2
                  type="button"
                  title="Edit Profile"
                  onClick={() => {
                    navigate("/profile-edit");
                  }}
                  customStyle={{
                    maxWidth: screenWidth < 1024 ? "165px" : "126px",
                  }}
                />
              </div>
            </div>
          </div>
          <div
            className={`w-full flex flex-col justify-center sm:justify-start items-center bg-primaryColor ${
              screenWidth < 1024 ? "rounded-b-xl" : "rounded-r-xl"
            } py-6 sm:pt-7 sm:pb-0`}
            style={{
              backgroundImage: `url(${
                screenWidth < 1024
                  ? "/illu_profile_dream.png"
                  : "/illu_profile_dream_desktop.svg"
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center bottom",
              width: "100%",
              minHeight: screenWidth < 1024 ? "117px" : "293px",
              gap: "26px",
            }}
          >
            <div className="w-full px-6">
              <p
                className="text-center font-bold text-base sm:text-2xl text-white"
                style={{ lineHeight: screenWidth < 1024 ? "12.33px" : "36px" }}
              >
                Kuliah Impian
              </p>
            </div>
            {dreams.length > 0 && !isDreamLoading && (
              <div
                className="w-full flex flex-col justify-start items-stretch pl-6 pr-3 sm:pl-9 sm:pr-4 "
                style={{ gap: "26px" }}
              >
                {dreams.map((item, index) => (
                  <ProfileDream
                    key={index}
                    screenWidth={screenWidth}
                    index={index}
                    major={item.major}
                    univ={item.univ}
                    editFunction={() => {
                      setIsEditDream(true);
                      setEditedDreamData(item);
                    }}
                    deleteFunction={() => {
                      deleteDream(item.id);
                      setIsDreamLoading(true);
                    }}
                  />
                ))}
              </div>
            )}
            {dreams.length < 2 &&
              (screenWidth < 1024 ? (
                <button
                  onClick={() => {
                    setIsEditDream(true);
                  }}
                >
                  <img src="/icon_add_dream.svg" />
                </button>
              ) : (
                <ButtonV2
                  onClick={() => {
                    setIsEditDream(true);
                  }}
                  title="Tambah Kuliah Impian"
                  secondary={true}
                  type="button"
                  customStyle={{
                    maxWidth: "190px",
                  }}
                  prefixIcon={
                    <PlusSmIcon
                      className="h-6 w-6 -mx-1"
                      style={{
                        color: "rgba(102, 46, 155, 1)",
                      }}
                    />
                  }
                />
              ))}
          </div>
        </div>

        {/* section Aktivitas Kamu */}
        <div className="flex flex-col w-full justify-start items-stretch mt-18">
          <p
            className="text-left text-2xl tablet:text-4xl font-bold"
            style={{ lineHeight: "56.89px", letterSpacing: "-0.71px" }}
          >
            Aktivitas Kamu
          </p>
          {screenWidth >= 1024 ? (
            <ul className="flex gap-4 py-4 mt-2">
              <li>
                <button
                  onClick={() => setTab("tmb")}
                  className={`text-center text-base font-semibold px-6 py-3 rounded-lg ${
                    tab === "tmb"
                      ? " text-white bg-tosca2"
                      : " text-tosca2 bg-backgroundGray4"
                  }`}
                >
                  Tes Minat (RIASEC)
                </button>
              </li>
              <li>
                <button
                  onClick={() => setTab("quiz")}
                  className={`text-center text-base font-semibold px-6 py-3 rounded-lg ${
                    tab === "quiz"
                      ? " text-white bg-tosca2"
                      : " text-tosca2 bg-backgroundGray4"
                  }`}
                >
                  Mini Quiz IPA - IPS
                </button>
              </li>
              <li>
                <button
                  onClick={() => setTab("upcoming")}
                  className={`text-center text-base font-semibold px-6 py-3 rounded-lg ${
                    tab === "upcoming"
                      ? " text-white bg-tosca2"
                      : " text-tosca2 bg-backgroundGray4"
                  }`}
                >
                  Kelas Mendatang
                </button>
              </li>
              <li>
                <button
                  onClick={() => setTab("past")}
                  className={`text-center text-base font-semibold px-6 py-3 rounded-lg ${
                    tab === "past"
                      ? " text-white bg-tosca2"
                      : " text-tosca2 bg-backgroundGray4"
                  }`}
                >
                  Kelas Sebelumnya
                </button>
              </li>
            </ul>
          ) : (
            <Listbox value={selectedTab} onChange={setSelectedTab}>
              <Listbox.Button
                className="relative h-12 w-full py-3 px-auto rounded-lg bg-tosca2 mt-8"
                onClick={() => {
                  setIsActivityDDOpen(!isActivityDDOpen);
                }}
              >
                <p className="text-center text-white text-base font-semibold">
                  {selectedTab.name}
                </p>
                {!isActivityDDOpen ? (
                  <ChevronDownIcon
                    className="w-6 h-6 object contain absolute top-3 right-3"
                    style={{ color: "#FFFFFF" }}
                  />
                ) : (
                  <ChevronUpIcon
                    className="w-6 h-6 object contain absolute top-3 right-3"
                    style={{ color: "#FFFFFF" }}
                  />
                )}
              </Listbox.Button>
              <Listbox.Options className="bg-white rounded-b-lg pb-7 px-4 pt-2 w-full">
                {tabs
                  .filter((tab) => tab.name !== selectedTab.name)
                  .map((tab) => (
                    <Listbox.Option
                      key={tab.id}
                      value={tab}
                      className="text-center text-tosca2 text-base font-semibold py-3 px-auto rounded-lg bg-backgroundGray4 mt-4"
                      disabled={tab.unavailable}
                      onClick={() => {
                        setTab(tab.code);
                        setIsActivityDDOpen(!isActivityDDOpen);
                      }}
                    >
                      {tab.name}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Listbox>
          )}
          <hr />
          {tab === "tmb" &&
            (tmbResults?.data.data.length > 0 ? (
              <div
                className="flex flex-row w-full overflow-x-auto pb-8 mt-8"
                style={{ gap: "24px" }}
              >
                {tmbResults?.data.data.map((data, index) => {
                  return (
                    <TMBResultCardV2
                      key={index}
                      data={data}
                      screenWidth={screenWidth}
                    />
                  );
                })}
              </div>
            ) : (
              <div
                className="w-full flex flex-col justify-start items-center"
                style={{ paddingTop: "30px" }}
              >
                <p
                  className="text-center font-normal text-base sm:text-xl text-black188"
                  style={{ lineHeight: screenWidth < 1024 ? "24.8px" : "31px" }}
                >
                  Kamu belum melakukan Tes Minat (RIASEC)
                </p>
                <ButtonV2
                  title="Lakukan Tes Minat (RIASEC) Sekarang"
                  onClick={() => {
                    navigate("/tes-minat");
                  }}
                  customStyle={{
                    marginTop: "39px",
                    maxWidth: screenWidth >= 1024 && "297.67px",
                  }}
                />
              </div>
            ))}
          {tab === "quiz" &&
            (miniQuizData ? (
              <div
                className="flex flex-row w-full overflow-x-auto pb-8 mt-8"
                style={{ gap: "24px" }}
              >
                {miniQuizData?.map((item, index) => {
                  return (
                    <MiniQuizResultCard
                      key={index}
                      data={item}
                      screenWidth={screenWidth}
                    />
                  );
                })}
              </div>
            ) : (
              <div
                className="w-full flex flex-col justify-start items-center"
                style={{ paddingTop: "30px" }}
              >
                <p
                  className="text-center font-normal text-base sm:text-xl text-black188"
                  style={{ lineHeight: screenWidth < 1024 ? "24.8px" : "31px" }}
                >
                  Kamu belum melakukan Mini Quiz IPA - IPS
                </p>
                <ButtonV2
                  title="Lakukan Mini Quiz IPA - IPS Sekarang"
                  onClick={() => {
                    navigate("/mini-quiz-ipa-ips");
                  }}
                  customStyle={{
                    marginTop: "39px",
                    maxWidth: screenWidth >= 1024 && "291.67px",
                  }}
                />
              </div>
            ))}
          {tab === "upcoming" && (
            <div>
              {(upcoming ? upcoming.data.data : []).length < 1 && (
                <div className="flex py-10 justify-center w-full">
                  <div className="text-center">
                    <h3 className="text-gray-800 text-2xl">
                      Kamu belum mendaftarkan diri ke kelas
                    </h3>
                    <div className="mt-4 mx-auto flex justify-center">
                      <Link to="/explore-kelas" className="block">
                        <Button type="primary">Daftar kelas sekarang</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="pt-6">
            {tab === "upcoming" && (
              <ul>
                {(upcoming ? upcoming.data.data : []).map((d, i) => {
                  return (
                    <li key={i}>
                      <CardClassRoom {...d} isSubscribe />
                    </li>
                  );
                })}
              </ul>
            )}
            {tab === "past" && (
              <ul>
                {(past ? past.data.data : []).map((d, i) => {
                  return (
                    <li key={i}>
                      <CardClassRoom {...d} isSubscribe />
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>

        {/* section Progress Pendaftaran */}
        <div className="flex flex-col w-full justify-start items-start mt-14 tablet:mt-22">
          <p
            className="text-left font-bold text-base tablet:text-4xl text-black1"
            style={{
              lineHeight: screenWidth < 1024 ? "20px" : "56.89px",
              letterSpacing: screenWidth >= 1024 && "-0.71px",
            }}
          >
            Wohoo! Pantau progres pendaftaran kampus impianmu
          </p>
          <div className="flex flex-row justify-start items-center gap-4 w-full overflow-x-scroll tablet:overflow-x-auto mt-6">
            {regisProgressTabs.map((item, index) => (
              <button
                key={index}
                onClick={() => {
                  setSelectedRegisProgressTab(regisProgressTabs[index]);
                }}
                className={`px-6 py-3 ${
                  index + 1 === selectedRegisProgressTab.id
                    ? "bg-tosca2 text-white"
                    : "bg-backgroundGray4 text-tosca2"
                } text-center font-semibold text-base rounded-lg w-max whitespace-nowrap`}
              >
                {item.name}
              </button>
            ))}
          </div>
          {admissionFormList && admissionFormList.length !== 0 && (
            <div
              className="flex flex-col tablet:flex-row gap-4 tablet:gap-8 w-full mt-6 overflow-x-auto overflow-y-auto tablet:overflow-y-hidden"
              style={{
                paddingLeft: "14px",
                paddingRight: "14px",
                minHeight: screenWidth < 1024 && "563px",
                maxHeight: screenWidth < 1024 && "563px",
              }}
            >
              {admissionFormList
                .filter(
                  (list) => list.form_status === selectedRegisProgressTab.code
                )
                .map((item, index) => (
                  <div key={index}>
                    <RegisProgressCard screenWidth={screenWidth} item={item} />
                  </div>
                ))}
            </div>
          )}
        </div>

        {/* section Daftar Favorit */}
        <p
          className="mt-22 text-left text-2xl tablet:text-4xl font-bold"
          style={{ lineHeight: "56.89px", letterSpacing: "-0.71px" }}
        >
          Daftar Favorit
        </p>
        <ul className="flex gap-4 py-4 mt-2">
          <li>
            <button
              onClick={() => setTabFavorite("university")}
              // className={`font-bold ${
              //   tabFavorite === "university"
              //     ? "tablet:text-lg text-purple-700 "
              //     : ""
              // }`}
              className={`text-center text-base font-semibold px-6 py-3 rounded-lg ${
                tabFavorite === "university"
                  ? " text-white bg-tosca2"
                  : " text-tosca2 bg-backgroundGray4"
              }`}
            >
              Kampus
            </button>
          </li>
          <li>
            <button
              onClick={() => setTabFavorite("major")}
              // className={`font-bold ${
              //   tabFavorite === "major" ? "tablet:text-lg text-purple-700 " : ""
              // }`}
              className={`text-center text-base font-semibold px-6 py-3 rounded-lg ${
                tabFavorite === "major"
                  ? " text-white bg-tosca2"
                  : " text-tosca2 bg-backgroundGray4"
              }`}
            >
              Jurusan
            </button>
          </li>
        </ul>
        <hr />
        {tabFavorite === "university" && (
          <div>
            {(university ? university.data.data : []).length < 1 && (
              <div className="flex py-10 justify-center">
                <div className="text-center">
                  <h3 className="text-gray-800 text-2xl">
                    Kamu belum menambahkan kampus ke daftar favorit
                  </h3>
                  <div className="mt-4 mx-auto flex justify-center">
                    <Link to="/explore-kampus" className="block">
                      <Button type="primary">Tambahkan Sekarang</Button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {tabFavorite === "major" && (
          <div>
            {(major ? major.data.data : []).length < 1 && (
              <div className="flex py-10 justify-center">
                <div className="text-center">
                  <h3 className="text-gray-800 text-2xl">
                    Kamu belum menambahkan jurusan ke daftar favorit
                  </h3>
                  <div className="mt-4 mx-auto flex justify-center">
                    <Link to="/explore-jurusan" className="block">
                      <Button type="primary">Tambahkan Sekarang</Button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="pt-6">
          {tabFavorite === "university" && (
            <div className="flex gap-4 overflow-x-auto">
              {(university ? university.data.data : []).map((d, i) => {
                return (
                  <div key={i} className="pb-4">
                    <CardUniversity isFavorite={d.favorite ?? false} {...d} />
                  </div>
                );
              })}
            </div>
          )}
          {tabFavorite === "major" && (
            <div className="flex gap-4 overflow-x-auto">
              {(major ? major.data.data : []).map((d, i) => {
                return (
                  <div key={i} className="pb-4">
                    <CardMajor isFavorite={d.favorite} {...d} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {/* Edit Dream */}
      <Transition appear show={isEditDream} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-100"
          onClose={() => {
            setIsEditDream(false);
          }}
          initialFocus={modalRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black/25"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
            />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div
              className="flex min-h-full items-start justify-center text-center"
              style={{ padding: "86px 30px" }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="w-full flex flex-col justify-center items-stretch transform overflow-hidden bg-white p-6 sm:p-10 text-left align-middle shadow-xl transition-all"
                  style={{
                    borderRadius: "10px",
                    gap: "22px",
                    maxWidth: "485px",
                  }}
                >
                  <p
                    className="text-center font-bold text-2xl text-black"
                    style={{ lineHeight: "30.24px" }}
                  >
                    Kuliah Impian
                  </p>
                  <form
                    onSubmit={onDreamSubmit}
                    className="w-full flex flex-col justify-start items-stretch"
                    style={{ gap: "22px" }}
                  >
                    <FormField
                      fieldType="autocomplete"
                      title="Jurusan impian"
                      placeholder="Masukkan jurusan impianmu"
                      value={editedDreamData && editedDreamData.mou_id}
                      isRequired={true}
                      ddOptions={availableMajor}
                      onInput={(id) => {
                        setEditedDreamData(prev => ({
                          ...prev,
                          mou_id: parseInt(id)
                        }));
                      }}
                    />
                    <FormField
                      fieldType="autocomplete" 
                      title="Kampus impian"
                      placeholder="Masukkan kampus impianmu"
                      value={editedDreamData && editedDreamData.uni_id}
                      isRequired={true}
                      ddOptions={availableUniv}
                      onInput={(id) => {
                        setEditedDreamData(prev => ({
                          ...prev,
                          uni_id: id
                        }));
                      }}
                    />
                    <div className="w-full flex flex-col justify-start items-stretch gap-3">
                      <ButtonV2 type="submit" title="Simpan" />
                      <ButtonV2
                        type="button"
                        title="Cancel"
                        secondary={true}
                        onClick={() => {
                          setIsEditDream(false);
                        }}
                      />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Just Finish TMB Modal */}
      <Transition appear show={isJustFinishTMB} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-100"
          onClose={() => {
            Cookies.remove("justFinishTMB");
            setIsJustFinishTMB(false);
          }}
          initialFocus={modalRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black/25"
              style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
            />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div
              className="flex min-h-full items-start justify-center text-center"
              style={{ padding: "86px 30px" }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className="w-full flex flex-col justify-center items-center transform overflow-hidden bg-white p-6 sm:p-10 text-left align-middle shadow-xl transition-all"
                  style={{
                    borderRadius: "10px",
                    gap: "22px",
                    maxWidth: "560px",
                  }}
                >
                  <img
                    src="/illu_finishtmb.png"
                    style={{ width: "162px", objectFit: "contain" }}
                  />
                  <p
                    className="text-center font-bold text-2xl text-black"
                    style={{ lineHeight: "30.24px" }}
                  >
                    Kamu telah menyelesaikan Tes Minat!
                  </p>
                  <p
                    className="text-center font-medium text-base text-black"
                    style={{ lineHeight: "20px" }}
                  >
                    Silahkan lihat hasil tes minat kamu di sini!
                  </p>
                  <div className="w-full grid grid-rows-2 gap-3">
                    <ButtonV2
                      title="Lihat Hasil Tes Minat"
                      onClick={() => {
                        navigate(
                          `/tmb-result-detail/jurusan/${tmbResults.data.data[0].id}`
                        );
                      }}
                    />
                    <ButtonV2
                      title="Cancel"
                      secondary={true}
                      onClick={() => {
                        Cookies.remove("justFinishTMB");
                        setIsJustFinishTMB(false);
                      }}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </LayoutV2>
  );
}
